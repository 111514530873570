let relayPointRoutes = [
  {
    path: '/relaypoints',
    name: 'relaypoints',
    meta: {
      permission: 'relay_point_manage',
      requiresAuth: true,
      parent: 'home',
      show: 'relaypoint_show',
      edit: 'relaypoint_edit',
      create: 'relaypoint_create',
      import: 'relaypoint_import',
      menu: 10,
      icon: 'mdi-alpha-p-circle-outline'
    },
    component: () =>
      import(/* webpackChunkName: "relaypoints" */ '../views/RelayPoints.vue')
  },
  {
    path: '/relaypoints/create',
    name: 'relaypoint_create',
    meta: {
      permission: 'relay_point_create',
      requiresAuth: true,
      parent: 'relaypoints',
      icon: 'mdi-alpha-p-circle-outline'
    },
    props: {
      edit: false
    },
    component: () =>
      import(/* webpackChunkName: "relaypoints" */ '../views/RelayPoints.vue')
  },
  {
    path: '/relaypoints/show/:id',
    name: 'relaypoint_show',
    meta: {
      permission: 'relay_point_read',
      requiresAuth: true,
      parent: 'relaypoints',
      edit: 'relaypoint_edit',
      icon: 'mdi-alpha-p-circle-outline'
    },
    component: () =>
      import(/* webpackChunkName: "relaypoints" */ '../views/RelayPoints.vue')
  },
  {
    path: '/relaypoints/import',
    name: 'relaypoint_import',
    meta: {
      permission: 'relay_point_update',
      requiresAuth: true,
      parent: 'relaypoints',
      icon: 'mdi-alpha-p-circle-outline'
    },
    props: {
      edit: false
    },
    component: () =>
      import(/* webpackChunkName: "relaypoints" */ '../views/RelayPoints.vue')
  },    
  {
    path: '/relaypoints/:id',
    name: 'relaypoint_edit',
    meta: {
      permission: 'relay_point_update',
      requiresAuth: true,
      parent: 'relaypoints',
      icon: 'mdi-alpha-p-circle-outline'
    },
    props: {
      edit: true
    },
    component: () =>
      import(/* webpackChunkName: "relaypoints" */ '../views/RelayPoints.vue')
  }
];

export { relayPointRoutes };