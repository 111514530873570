let solidaryVolunteerRoutes = [
  {
    path: '/solidaryvolunteers',
    name: 'solidaryvolunteers',
    meta: {
      permission: 'solidary_volunteer_list',
      requiresAuth: true,
      parent: 'solidary',
      show: 'solidaryvolunteer_show',
      edit: 'solidaryvolunteer_edit',
      sub: true,
      menu: 2,
      icon: 'mdi-steering',
      specialDisplayRules: {
        solidaryTransport: true    // Display item if Structure::solidaryTransport === true
      }
    },
    component: () =>
      import(/* webpackChunkName: "solidaryvolunteers" */ '../views/SolidaryVolunteers.vue')
  },
  {
    path: '/solidaryvolunteers/show/:id',
    name: 'solidaryvolunteer_show',
    meta: {
      permission: 'solidary_volunteer_read',
      requiresAuth: true,
      parent: 'solidaryvolunteers',
      edit: 'solidaryvolunteer_edit',
      icon: 'mdi-steering'
    },
    component: () =>
      import(/* webpackChunkName: "solidaryvolunteers" */ '../views/SolidaryVolunteers.vue')
  },
  {
    path: '/solidaryvolunteers/:id',
    name: 'solidaryvolunteer_edit',
    meta: {
      permission: 'solidary_volunteer_update',
      requiresAuth: true,
      parent: 'solidaryvolunteers',
      icon: 'mdi-steering'
    },
    props: {
      edit: true
    },
    component: () =>
      import(/* webpackChunkName: "solidaryvolunteers" */ '../views/SolidaryVolunteers.vue')
  }
];

export { solidaryVolunteerRoutes };