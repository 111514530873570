// uncomment if needed
// import RequestService from './request.service';
import { getItems, getItem } from './common.service';

class IconService {
  route = 'admin/icons';
  getItems(sortBy, sortDesc, page, perPage, filter) {
    return getItems(this.route, sortBy, sortDesc, page, perPage, filter).then( response => Promise.resolve(response), error => Promise.reject(error) );
  };
  getItem(id) {
    return getItem(this.route,id).then( response => Promise.resolve(response), error => Promise.resolve(error) );
  };
}

export default new IconService();