import { isEqual, cloneDeep } from 'lodash';

// common store module, contains base actions, mutations and getters
// it's used by other modules that can overload it
// we use function and not const, so each object returned is an instance of the object
// otherwise, using eg. 'const initialState = ...' would end to a unique instance of initialState (singleton), shared by all objects !

// default initialState for each module
function initialState() {
  return {
    // mandatory --
    // items for collection resource management
    items: [],
    total: 0,
    // item for item resource management
    item: null,
    // edited item, used to compare with initial item
    editedItem: null,
    // filters
    filter: {},
    // options (sort, page, perPage...)
    options: {},
    // optional filters
    selectedOptionalFilters: [],
    // errors
    error: false,
    // loading
    loading: false,
    // loading export
    loadingExport: false,
    // import
    importLoading: false,
    importResponse: {}
  };
}

// default actions for each module
function defaultActions() {
  return {
    saveOptions({commit}, options) {
      commit('SAVE_OPTIONS', options);
    },
    saveFilter({commit}, filter) {
      commit('SAVE_FILTER', filter);
    },
    resetError({commit}) {
      commit('SAVE_ERROR',false);
    },
    saveSelectedOptionalFilters({commit, state}, filters) {
      const { selectedOptionalFilters, alwaysOnFilters } = filters;
      // we store the new selected optional filters
      commit('SAVE_SELECTED_OPTIONAL_FILTERS', selectedOptionalFilters);
      // we remove the filtering that is not concerned anymore (filters that are removed from the selected optional list)
      let newFilter = {};
      Object.keys(state.filter).forEach( key => {
        if (selectedOptionalFilters.includes(key)) {
        // the current state filter is in the selected optional fields => we keep it
          newFilter[key] = state.filter[key];
        } else if (alwaysOnFilters.includes(key)) {
        // the current state filter is in the permanent optional fields => we keep it
          newFilter[key] = state.filter[key];
        }
      });
      if (!isEqual(state.filter, newFilter)) {
        // if the new filter is different than the older one, we replace it in the store
        commit('SAVE_FILTER', newFilter);
      }
    },
    createItem({commit}, fields ) {
      let item = {};
      fields.fields.forEach( field => {
        if (field.name && field.value) {
          // there's a default value
          item[field.name] = field.value;
        } else {
          item[field] = null;
        }
      });
      commit('SAVE_ITEM', item );
    },
    // used to sync local store item for locally managed fields (eg. reset image fields)
    saveItem({commit}, item) {
      commit('SAVE_ITEM', item);
    },
    saveEditedItem({commit}, item) {
      commit('SAVE_EDITED_ITEM', item);
    },
    initEditedItem({commit}) {
      commit('INIT_EDITED_ITEM');
    },
    clearItem({commit}) {
      commit('CLEAR_ITEM');
    }
  };
};

// default mutations for each module
function defaultMutations() {
  return {
    // mandatory --
    SAVE_ITEMS(state, items) {
      state.items = items.items;
      state.total = items.total;
    },
    SAVE_ITEM(state, item) {
      state.item = item;
      state.editedItem = cloneDeep(item);
    },
    SAVE_EDITED_ITEM(state, item) {
      state.editedItem = item;
    },
    INIT_EDITED_ITEM(state) {
      state.editedItem = cloneDeep(state.item);
    },
    CLEAR_ITEM(state) {
      state.item = null;
      state.editedItem = null;
    },
    SAVE_FILTER(state, filter) {
      state.filter = filter;
    },
    SAVE_SELECTED_OPTIONAL_FILTERS(state, selectedOptionalFilters) {
      state.selectedOptionalFilters = selectedOptionalFilters;
    },
    SAVE_OPTIONS(state, options) {
      state.options = options;
    },
    SAVE_ERROR(state, error) {
      state.error = error;
    },
    SAVE_LOADING(state, loading) {
      state.loading = loading;
    },
    SAVE_LOADING_EXPORT(state, loadingExport) {
      state.loadingExport = loadingExport;
    },
    IMPORT_LOADING(state, loading) {
      state.importLoading = loading;
    },
    IMPORT_RESPONSE(state, response) {
      state.importResponse = response;
    },    
  };
};

// default getters for each module
function defaultGetters() {
  return {
    item (state) {
      return state.item;
    },
    editedItem(state) {
      return state.editedItem;
    },
    items (state) {
      return state.items;
    },
    total (state) {
      return state.total;
    },
    filter (state) {
      return state.filter;
    },
    selectedOptionalFilters (state) {
      return state.selectedOptionalFilters;
    },
    options (state) {
      return state.options;
    },
    error (state) {
      return state.error;
    },
    loading (state) {
      return state.loading;
    },
    loadingExport (state) {
      return state.loadingExport;
    },
    importLoading (state) {
      return state.importLoading;
    },
    importResponse (state) {
      return state.importResponse;
    }     
  };
};

export { initialState, defaultActions, defaultMutations, defaultGetters };