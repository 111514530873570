let solidaryRecordRoutes = [
  {
    path: '/solidaryrecords',
    name: 'solidaryrecords',
    meta: {
      permission: 'solidary_list',
      requiresAuth: true,
      parent: 'solidary',
      show: 'solidaryrecord_show',
      edit: 'solidaryrecord_edit',
      create: 'solidaryrecord_create',
      sub: true,
      menu: 3,
      icon: 'mdi-seat-passenger'
    },
    component: () =>
      import(/* webpackChunkName: "solidaryrecords" */ '../views/SolidaryRecords.vue')
  },
  {
    path: '/solidaryrecords/create',
    name: 'solidaryrecord_create',
    meta: {
      permission: 'solidary_create',
      requiresAuth: true,
      parent: 'solidaryrecords',
      icon: 'mdi-seat-passenger'
    },
    props: {
      edit: false
    },
    component: () =>
      import(/* webpackChunkName: "solidaryrecords" */ '../views/SolidaryRecords.vue')
  },
  {
    path: '/solidaryrecords/show/:id',
    name: 'solidaryrecord_show',
    meta: {
      permission: 'solidary_read',
      requiresAuth: true,
      parent: 'solidaryrecords',
      edit: 'solidaryrecord_edit',
      icon: 'mdi-seat-passenger'
    },
    component: () =>
      import(/* webpackChunkName: "solidaryrecords" */ '../views/SolidaryRecords.vue')
  },
  {
    path: '/solidaryrecords/:id',
    name: 'solidaryrecord_edit',
    meta: {
      permission: 'solidary_update',
      requiresAuth: true,
      parent: 'solidaryrecords',
      icon: 'mdi-seat-passenger'
    },
    props: {
      edit: true
    },
    component: () =>
      import(/* webpackChunkName: "solidaryrecords" */ '../views/SolidaryRecords.vue')
  }
];

export { solidaryRecordRoutes };