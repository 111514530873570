import SolidaryRecordService from '../services/solidaryRecord.service';
import SolidaryStructureService from '@/services/solidaryStructure.service';
import MessageService from '../services/message.service';
import ExternalJourneysService from '../services/externalJourneys.service';
import { initialState, defaultActions, defaultMutations, defaultGetters } from './common.module';
import moment from 'moment-timezone';

export const solidaryRecordModule = {
  namespaced:true,
  state: {
    ...initialState(),
    // constants
    STATUS_CREATED: 0,
    STATUS_TAKEN_ACCOUNT: 1,
    STATUS_LOOKING_FOR_SOLUTION: 2,
    STATUS_FOLLOW_UP: 3,
    STATUS_CLOSED: 4,
    STATUS_CLOSED_FOR_EDITION: 6,
    // solidary actions
    solidaryActions: null,
    // default progression lower than 100
    filter: { progression: 100},
    // sort by carpool date asc by default
    options: { sortBy: [ 'fromDate'], sortDesc: [ false ]},
    // warning when editing a sensible property
    editWarn: null,
    externalJourneysRequestType: null,
    externalJourneys: [],
    externalJourneysLoading: false,
    driverContacted: false
  },
  mutations: {
    ...defaultMutations(),
    SAVE_SOLIDARY_ACTIONS(state, solidaryActions) {
      state.solidaryActions = solidaryActions;
    },
    SAVE_EDIT_WARN(state, editWarn) {
      state.editWarn = editWarn;
    },
    ADD_ITEMS(state, externalJourneys) {
      externalJourneys.items.forEach( item => {
        if(state.externalJourneysRequestType == 'outward'){
          state.item.carpools.outward.push(item);
        }
        else{
          state.item.carpools.return.push(item);
        }
      });
    },
    SAVE_EXTERNAL_JOURNEYS_LOADING(state, externalJourneysLoading) {
      state.externalJourneysLoading = externalJourneysLoading;
    },
    DRIVER_CONTACTED(state) {
      state.driverContacted = true;
    }
  },
  getters: {
    ...defaultGetters(),
    solidaryActions (state) {
      return state.solidaryActions;
    },
    editWarn (state) {
      return state.editWarn;
    },
    statusClosedForUpdate(state) {
      return state.STATUS_CLOSED_FOR_EDITION;
    },
    statusIcon(state) {
      if (state.item && state.item.status) {
        switch (state.item.status) {
        case state.STATUS_CREATED:
          return 'mdi-new-box';
        case state.STATUS_TAKEN_ACCOUNT:
          return 'mdi-check';
        case state.STATUS_LOOKING_FOR_SOLUTION:
          return 'mdi-target-account';
        case state.STATUS_FOLLOW_UP:
          return 'mdi-trending-neutral';
        case state.STATUS_CLOSED:
          return 'mdi-close';
        case state.STATUS_CLOSED_FOR_EDITION:
          return 'mdi-clock-start';
        }
      }
      return 'mdi-new-box';
    },
    statusColor(state) {
      if (state.item && state.item.status) {
        switch (state.item.status) {
        // case state.STATUS_CREATED:
        //   return 'blue';
        // case state.STATUS_TAKEN_ACCOUNT:
        //   return 'green';
        // case state.STATUS_LOOKING_FOR_SOLUTION:
        //   return 'brown';
        // case state.STATUS_FOLLOW_UP:
        //   return 'yellow';
        // case state.STATUS_CLOSED:
        //   return 'grey';
        // case state.STATUS_CLOSED_FOR_EDITION:
        //   return 'red';
        default: return 'blue';
        }
      }
      return 'blue';
    },
    statusText(state) {
      if (state.item && state.item.status) {
        return 's.status.'+state.item.status;
      }
      return 's.status.0';
    },
    externalJourneys(state) {
      return state.externalJourneys;
    },
    externalJourneysLoading(state) {
      return state.externalJourneysLoading;
    },
    driverContacted(state){
      return state.driverContacted;
    }
  },
  actions: {
    ...defaultActions(),
    createItem({commit, rootState} ) {
      commit('SAVE_LOADING', true);
      // get the full current structure of the user
      SolidaryStructureService.getItem(rootState.auth.muser.currentSolidaryStructure)
        .then( structure => {
          let item = {
            beneficiary: {
              email: null,
              telephone: null,
              givenName: null,
              familyName: null,
              gender: null,
              newsSubscription: null,
              birthDate: null,
              homeAddress: null
            },
            proofs: structure.structureProofsRequester ? structure.structureProofsRequester.map( proof => ({id: proof.id, value: proof.checkbox ? false : null}) ) : [],
            subject: null,
            needs: [],
            additionalSubject: null,
            additionalNeed: null,
            origin: null,
            destination: null,
            destinationAny: null,
            regular: false,
            punctualOutwardDateChoice: null,
            punctualOutwardChosenMinDate: null,
            punctualOutwardMinDate: null,
            punctualOutwardMaxDate: null,
            punctualOutwardTimeChoice: null,
            punctualOutwardChosenMinTime: null,
            punctualOutwardMinTime: null,
            punctualOutwardMaxTime: null,
            punctualReturnDateChoice: null,
            punctualReturnChosenDateTime: null,
            punctualReturnDate: null,
            punctualReturnTime: null,
            regularSchedules: [
              {
                days: [],
                outwardTime: null,
                returnTime: null
              }
            ],
            regularDateChoice: null,
            regularMinDate: null,
            regularMaxDate: null,
            structure: structure,
          };
          commit('SAVE_ITEM', item);
        }).catch( () => {
          MessageService.error('created','solidaryrecord');
          commit('SAVE_ERROR', true);
        }).finally( () => {
          commit('SAVE_LOADING', false);
        });
    },
    loadItems({commit, state}) {
      commit('SAVE_LOADING', true);
      SolidaryRecordService.getItems(state.options.sortBy, state.options.sortDesc, state.options.page, state.options.itemsPerPage, state.filter).then(response => {
        // check if current options are still valid : last load may have changed the number of items, so current page may not be valid for example
        let nbpages = Math.ceil(response.total/state.options.itemsPerPage);
        if (nbpages<state.options.page) {
          let newOptions = state.options;
          if (nbpages>0) {
            newOptions.page = nbpages;
          } else {
            newOptions.page = 1;
          }
          commit('SAVE_OPTIONS',newOptions);
        }
        commit('SAVE_ITEMS', response);
      }).catch( () => {
        commit('SAVE_ERROR', true);
      }).finally( () => {
        commit('SAVE_LOADING', false);
      });
    },
    loadItem({commit, state}, { id, fields}) {
      // clear item if given id is different than current id
      if (state.item && state.item.id && id != state.item.id) {
        commit('SAVE_ITEM', null);
      }
      commit('SAVE_LOADING', true);
      SolidaryRecordService.getItem(id).then(response => {
        // complete the item with potential nullable fields that were not retrieved
        fields.forEach( item => {
          if (response[item] === undefined) {
            response[item] = null;
          }
        });
        commit('SAVE_ITEM', response);
      }).catch( () => {
        MessageService.error('loaded','solidaryrecord');
        commit('SAVE_ERROR', true);
      }).finally( () => {
        commit('SAVE_LOADING', false);
      });
    },
    loadItemForEdition({commit, state}, { id, fields}) {
      // clear item if given id is different than current id
      if (state.item && state.item.id && id != state.item.id) {
        commit('SAVE_ITEM', null);
      }
      commit('SAVE_LOADING', true);
      SolidaryRecordService.getItem(id).then(response => {
        // complete the item with potential nullable fields that were not retrieved
        fields.forEach( item => {
          if (response[item] === undefined) {
            response[item] = null;
          }
        });
        // get the full structure to retrieve the proofs and needs
        SolidaryStructureService.getItem(response.structureId)
          .then( structure => {
            let additionalNeed = null;
            response.needs.forEach( userNeed => {
              if (structure.needs.find( need => userNeed.id === need.id ) === undefined) {
                additionalNeed = userNeed.label;
              }
            });
            let regularSchedules = [
              {
                days: [],
                outwardTime: null,
                returnTime: null
              }
            ];
            if (response.frequency == 2) {
              regularSchedules = response.schedules.map( schedule => {
                let days = [];
                if (schedule.mon) days.push('mon');
                if (schedule.tue) days.push('tue');
                if (schedule.wed) days.push('wed');
                if (schedule.thu) days.push('thu');
                if (schedule.fri) days.push('fri');
                if (schedule.sat) days.push('sat');
                if (schedule.sun) days.push('sun');
                return {
                  days: days,
                  outwardTime: moment.utc(schedule.outwardTime).format('HH:mm'),
                  returnTime: schedule.returnTime ? moment.utc(schedule.returnTime).format('HH:mm') : null
                };
              });
            }
            // construct the item from the data retrieved
            let item = {
              id: response.id,
              beneficiary: {
                email: response.email,
                telephone: response.telephone,
                givenName: response.givenName,
                familyName: response.familyName,
                gender: response.gender,
                newsSubscription: response.newsSubscription,
                birthDate: response.birthDate,
                homeAddress: response.homeAddress
              },
              proofs: structure.structureProofsRequester ? structure.structureProofsRequester.map( proof => {
                let returnProof = { id: proof.id, value: proof.checkbox ? false : null};
                const userProof = response.proofs.find( userProof => userProof.structureProofId === proof.id);
                if (userProof) {
                  if (userProof.fileName) returnProof.fileName = userProof.fileName;
                  if (userProof.fileSize) returnProof.fileSize = userProof.fileSize;
                  if (userProof.originalName) returnProof.originalName = userProof.originalName;
                  if (proof.inputType === 'selectbox' || proof.inputType === 'radio') {
                    const options = proof.options.split(';');
                    let akey = Object.keys(options).find(key => options[key] == userProof.value);
                    if (akey !== undefined) {
                      returnProof.value = proof.acceptedValues.split(';')[akey];
                    }
                  } else {
                    returnProof.value = userProof.value;
                  }
                }
                return returnProof;
              }): [],
              subject: structure.subjects.find( subject => subject.id === response.subjectId ) ? structure.subjects.find( subject => subject.id === response.subjectId ).id : 9999,
              needs: response.needs.filter( userNeed => {
                if (structure.needs.find( need => userNeed.id === need.id ) !== undefined) {
                  return true;
                }
                return false;
              }).map( need => need.id),
              additionalSubject: structure.subjects.find( subject => subject.id === response.subjectId ) ? null : response.subject,
              additionalNeed: additionalNeed,
              origin: response.origin,
              destination: response.origin.displayLabel[0] === response.destination.displayLabel[0] ? null : response.destination,
              destinationAny: response.origin.displayLabel[0] === response.destination.displayLabel[0],
              regular: response.frequency == 2,
              punctualOutwardDateChoice: response.punctualOutwardDateChoice,
              punctualOutwardChosenMinDate: (response.punctualOutwardDateChoice == 1 && response.outwardDate) ? moment(response.outwardDate).format('YYYY-MM-DD') : null,
              punctualOutwardMinDate: response.fromDate ? moment(response.fromDate).format('YYYY-MM-DD') : null,
              punctualOutwardMaxDate: (response.punctualOutwardDateChoice > 1 && response.toDate) ? moment(response.toDate).format('YYYY-MM-DD') : null,
              punctualOutwardTimeChoice: response.punctualOutwardTimeChoice,
              punctualOutwardChosenMinTime: (response.punctualOutwardTimeChoice == 1 && response.outwardTime) ? moment.utc(response.outwardTime).format('HH:mm') : null,
              punctualOutwardMinTime: response.outwardMinTime ? moment.utc(response.outwardMinTime).format('HH:mm') : null,
              punctualOutwardMaxTime: response.outwardMaxTime ? moment.utc(response.outwardMaxTime).format('HH:mm') : null,
              punctualReturnDateChoice: response.punctualReturnDateChoice,
              punctualReturnChosenDateTime: (response.punctualReturnDateChoice == 5 && response.returnDate) ? moment(moment(response.returnDate).format('YYYY-MM-DD')+(response.returnTime ? (' '+moment.utc(response.returnTime).format('HH:mm')) : '')).format('YYYY-MM-DD HH:mm') : null,
              punctualReturnDate: (response.punctualReturnDateChoice == 5 && response.returnDate) ? moment(response.returnDate).format('YYYY-MM-DD') : null,
              punctualReturnTime: (response.punctualReturnDateChoice == 5 && response.returnTime) ? moment.utc(response.returnTime).format('HH:mm') : null,
              regularSchedules: regularSchedules,
              regularDateChoice: response.regularDateChoice,
              regularMinDate: response.fromDate ? moment(response.fromDate).format('YYYY-MM-DD') : null,
              regularMaxDate: response.toDate ? moment(response.toDate).format('YYYY-MM-DD') : null,
              structure: structure
            };
            commit('SAVE_ITEM', item);
          });
      }).catch( () => {
        MessageService.error('loaded','solidaryrecord');
        commit('SAVE_ERROR', true);
      }).finally( () => {
        commit('SAVE_LOADING', false);
      });
    },
    postItem( { commit }, item) {
      commit('SAVE_LOADING', true);
      // there may be proof files to send, we need to send them on different api calls after the solidary record has been created
      SolidaryRecordService.postItem(item).then( async response => {
        // check for file proofs
        // promises array, to be sure that all file proofs are posted successfully, as well as associate if it is needed
        var promises = [];
        // check each proofs element for files
        item.proofs.forEach( proof => {
          if (proof.value?.name) {
            // the element is a file, we upload it
            let formData = new FormData();
            formData.append('solidaryId', response.id);
            formData.append('proofId', proof.id);
            formData.append('file', proof.value);
            promises.push(
              SolidaryRecordService.postProofFile(formData)
                .catch( () => {
                  return Promise.reject();
                })
            );
          }
        });
        await Promise.all(promises);
        // all is ok, we return the promise to continue the process
        return await Promise.resolve(response);
      }).then( response => {
        commit('SAVE_ITEM', response);
        MessageService.success('created','solidaryrecord');
      }).catch( () => {
        MessageService.error('created','solidaryrecord');
        commit('SAVE_ERROR', true);
      }).finally( () => {
        commit('SAVE_LOADING', false);
      });
    },
    patchItem({commit}, data) {
      const { id, item } = data;
      commit('SAVE_LOADING', true);
      SolidaryRecordService.patchItem(id, item).then(async response => {
        // check for file proofs
        // promises array, to be sure that all file proofs are posted successfully, as well as associate if it is needed
        var promises = [];
        if (item.proofs) {
          // check each proofs element for files
          item.proofs.forEach( proof => {
            if (proof.value?.name) {
            // the element is a file, we upload it
              let formData = new FormData();
              formData.append('solidaryId', response.id);
              formData.append('proofId', proof.id);
              formData.append('file', proof.value);
              promises.push(
                SolidaryRecordService.postProofFile(formData)
                  .catch( () => {
                    return Promise.reject();
                  })
              );
            }
          });
        }
        await Promise.all(promises);
        // all is ok, we return the promise to continue the process
        return await Promise.resolve(response);
      }).then( response => {
        commit('SAVE_ITEM', response);
        MessageService.success('updated','solidaryrecord');
      }).catch( () => {
        MessageService.error('updated','solidaryrecord');
        commit('SAVE_ERROR', true);
      }).finally( () => {
        commit('SAVE_LOADING', false);
      });
    },
    deleteItem({commit}, id) {
      commit('SAVE_LOADING', true);
      SolidaryRecordService.deleteItem(id).then( () => {
        MessageService.success('deleted','solidaryrecord');
        commit('SAVE_ITEM', null);
      }).catch( () => {
        MessageService.error('deleted','solidaryrecord');
        commit('SAVE_ERROR', true);
      }).finally( () => {
        commit('SAVE_LOADING', false);
      });
    },
    loadActions({commit}) {
      commit('SAVE_LOADING', true);
      SolidaryRecordService.getActions().then(response => {
        commit('SAVE_SOLIDARY_ACTIONS', response.items);
      }).catch( () => {
        commit('SAVE_ERROR', true);
      }).finally( () => {
        commit('SAVE_LOADING', false);
      });
    },
    setEditWarn({commit}, editWarn) {
      commit('SAVE_EDIT_WARN', editWarn);
    },
    saveEditedItemProof({commit, state}, { index, id, value }) {
      let newEditedItem = { ...state.editedItem};
      newEditedItem.proofs[index].id = id;
      newEditedItem.proofs[index].value = value;
      commit('SAVE_EDITED_ITEM', newEditedItem);
    },
    removeEditedItemProof({commit, state}, { index, id }) {
      let newEditedItem = { ...state.editedItem};
      newEditedItem.proofs[index] = {
        id: id,
        value: null
      };
      commit('SAVE_EDITED_ITEM', newEditedItem);
    },
    getExternalJourneys({commit, state}, data){
      state.externalJourneysRequestType = data.type;
      commit('SAVE_LOADING', true);
      commit('SAVE_EXTERNAL_JOURNEYS_LOADING', true);
      ExternalJourneysService.getItems(data).then( response => {
        commit('ADD_ITEMS', response);
      }).catch( () => {
        commit('SAVE_ERROR', true);
      }).finally( () => {
        commit('SAVE_LOADING', false);
        commit('SAVE_EXTERNAL_JOURNEYS_LOADING', false);
      });
    },
    contactExternalDriver({commit, state}, data){
      state.driverContacted = false;
      ExternalJourneysService.contactDriver(data).then( response => {
        MessageService.success('driverContacted','solidaryrecord');
        commit('DRIVER_CONTACTED', response);
      }).catch( () => {
        MessageService.error('driverContacted','solidaryrecord');
      });
    }    
  }
};
