// uncomment if needed
// import RequestService from './request.service';
import { getItems, postItem } from './common.service';
import moment from 'moment-timezone';

class ExternalJourneysService {
  route = 'admin/external_journeys';
  routeContactDriver = 'external_connections';
  routeExternalJourneyProviders = 'admin/external_journey_providers';
  defaultFrequency = 'punctual';
  
  getItems(data, sortBy, sortDesc, page, perPage, filter) {
    
    let frequency = this.defaultFrequency;

    if(data.frequency){
      if(data.frequency == 1){
        frequency = 'punctual';
      }
      else if(data.frequency == 2){
        frequency = 'regular';
      }
    }

    let params = {
      'from_latitude':data.fromLat,
      'from_longitude':data.fromLon,
      'to_latitude':data.toLat,
      'to_longitude':data.toLon,
      'outward_mindate':data.minDate,
      'outward_maxdate':data.maxDate,
      'driver':1,
      'passenger':0,
      'frequency': frequency,
    };

    moment.locale('en');
    if(frequency == 'punctual'){
      let day = moment(data.minDate).format('dddd').toLocaleLowerCase();

      params['days_'+day] = 1;
      params[day+'_mintime'] = data.minTime;
      params[day+'_maxtime'] = data.maxTime;
    }
    else if(frequency == 'regular'){
      data.schedules.forEach( schedule => {
        Object.entries(schedule).forEach(([key, value]) => {
          if(key == 'mon' && value === true){
            params['days_monday'] = 1;
            if(schedule[data.type+'Time'] !== null){
              params['monday_mintime'] = moment.utc(schedule[data.type+'Time']).format('HH:mm:ss');
            }
          }
          if(key == 'tue' && value === true){
            params['days_tuesday'] = 1;
            if(schedule[data.type+'Time'] !== null){
              params['tuesday_mintime'] = moment.utc(schedule[data.type+'Time']).format('HH:mm:ss');
            }
          }
          if(key == 'wed' && value === true){
            params['days_wednesday'] = 1;
            if(schedule[data.type+'Time'] !== null){
              params['wednesday_mintime'] = moment.utc(schedule[data.type+'Time']).format('HH:mm:ss');
            }
          }
          if(key == 'thu' && value === true){
            params['days_thursday'] = 1;
            if(schedule[data.type+'Time'] !== null){
              params['thursday_mintime'] = moment.utc(schedule[data.type+'Time']).format('HH:mm:ss');
            }
          }
          if(key == 'fri' && value === true){
            params['days_friday'] = 1;
            if(schedule[data.type+'Time'] !== null){
              params['friday_mintime'] = moment.utc(schedule[data.type+'Time']).format('HH:mm:ss');
            }
          }
          if(key == 'sat' && value === true){
            params['days_saturday'] = 1;
            if(schedule[data.type+'Time'] !== null){
              params['saturday_mintime'] = moment.utc(schedule[data.type+'Time']).format('HH:mm:ss');
            }
          }
          if(key == 'sun' && value === true){
            params['days_sunday'] = 1;
            if(schedule[data.type+'Time'] !== null){
              params['sunday_mintime'] = moment.utc(schedule[data.type+'Time']).format('HH:mm:ss');
            }
          }
        });        
      });
    }
    let urlParams = new URLSearchParams(params);
    
    return getItems(this.route+'?'+urlParams, sortBy, sortDesc, page, perPage, filter)
      .then( response => {
        return Promise.resolve(response);
      })
      .catch( error => Promise.reject(error) );
  };

  contactDriver(data){

    const params = {
      'carpoolerUuid': data.carpooler.toString(),
      'provider': data.provider,
      'journeysUuid': data.journeyId.toString(),
      'content': data.message,
      'role': 2,
    };

    return postItem(this.routeContactDriver, params)
      .then( response => {
        return Promise.resolve(response);
      })
      .catch( error => Promise.reject(error) );    
  };

  getExternalJourneyProviders(){
    return getItems(this.routeExternalJourneyProviders)
      .then( response => {
        return Promise.resolve(response);
      })
      .catch( error => Promise.reject(error) );
  };
}

export default new ExternalJourneysService();