<template>
  <v-card
    class="mx-auto"
    width="400"
    elevation="8"
    outlined
    :loading="loading"
  >
    <v-card-title class="justify-center">
      <v-avatar color="primary">
        <v-icon>
          mdi-lock
        </v-icon>
      </v-avatar>
    </v-card-title>

    <v-card-text class="text--primary">
      <v-form 
        ref="form"
        v-model="valid"
      >
        <v-text-field
          v-model="muser.email"
          :label="$t('identifier.name')"
          required
          :rules="emailRules"
          :disabled="loading"
        />
        <v-text-field
          v-model="muser.password"
          type="password"
          :label="$t('password.name')"
          required
          :rules="passwordRules"
          :disabled="loading"
        />

        <v-btn
          class="mr-4 primary"
          :disabled="!valid || loading"
          :loading="loading"
          type="submit"
          @click="validate"
        >
          {{ $t('connection') }}
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import PageTitleMixin from '@/mixins/PageTitle';
import Muser from '../models/muser';
import {messages_views_en, messages_views_fr} from '@/translations/views/Login';

export default {
  i18n: {
    messages: {
      'en': messages_views_en,
      'fr': messages_views_fr
    }
  },
  mixins: [
    PageTitleMixin
  ],
  data () {
    return {
      muser: new Muser('', ''),
      email: '',
      password: '',
      loading: false,
      valid: false,
      emailRules: [
        v => !!v || this.$t('identifier.required'),
        v => /.+@.+\..+/.test(v) || this.$t('identifier.invalid'),
      ],
      passwordRules: [
        v => !!v || this.$t('password.required')
      ]
    };
  },
  computed: {
    loggedIn() {
      return this.$store.getters['auth/loggedIn'];
    }
  },
  created() {
    if (this.loggedIn) {
      this.redirectWhenLogged();
    } else {
      this.$vuetify.theme.dark = JSON.parse(localStorage.getItem('dark'));
      this.$store.commit('SET_LAYOUT', 'login-layout');
    }
  },
  methods: {
    validate (e) {
      e.preventDefault();
      this.loading = true;
      if (this.$refs.form.validate()) {  
        this.$store.dispatch('auth/login', this.muser)
          .then( (response) => {
            localStorage.setItem('eecAvailable', response.eecAvailable);
            this.redirectWhenLogged();
          })
          .catch( () => {
            this.loading = false;    
          });
      } else {
        this.loading = false;
      }
    },
    redirectWhenLogged() {
      this.loading = false;
      this.$router.push('/').catch(()=>{});
    }
  }

  
};
</script>