import RoleService from '../services/role.service';
import { initialState, defaultActions, defaultMutations, defaultGetters } from './common.module';

export const roleModule = {
  namespaced:true,
  state: initialState(),  // mix with custom state if needed
  mutations: {
    ...defaultMutations()
    // add custom mutations
  },
  getters: {
    ...defaultGetters()
    // add custom getters
  },
  actions: {
    ...defaultActions(),
    loadItems({commit}) {
      RoleService.getGrantableRoles().then(response => {
        commit('SAVE_ITEMS', response);
      }).catch( () => {
        commit('SAVE_ERROR', true);
      });
    }
  }
};
