import RequestService from './request.service';

class RoleService {
  getGrantableRoles() {
    return RequestService
      .get('permissions/grantable', {}, 'hydra')
      .then(response => {
        return Promise.resolve({
          items: response.data['hydra:member'].map(element => ({
            id: element,
            value: element
          })),
          total: response.data['hydra:totalItems']
        });
      })
      .catch( error => Promise.resolve(error) );
  }
}

export default new RoleService();