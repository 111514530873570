import RequestService from "./request.service";
import UserService from "../services/user.service";
import PermissionService from "../services/permission.service";
import SolidaryStructureService from "../services/solidaryStructure.service";
import ExternalJourneysService from "../services/externalJourneys.service";
import PlatformService from "../services/platform.service";

import getEnv from "@/utils/env";

import { hasPermission } from "./solidary";

class AuthService {
  login(muser) {
    return RequestService.post(
      "login",
      {
        username: muser.email,
        password: muser.password,
      },
      {},
      "json",
      false,
      false
    )
      .then((response) => {
        if (response.data.token) {
          localStorage.setItem("token", JSON.stringify(response.data.token));
        } else {
          return Promise.reject("Token not found in response");
        }
        if (response.data.refreshToken) {
          localStorage.setItem(
            "refreshToken",
            JSON.stringify(response.data.refreshToken)
          );
        } else {
          return Promise.reject("Refresh token not found in response");
        }
        return Promise.resolve();
      })
      .then(() => {
        // get user info
        // - token exists => we check it
        // - user doesn't exist yet => don't check idle
        return UserService.getMe(false).then((user) => {
          muser.givenName = user.givenName;
          muser.shortFamilyName = user.shortFamilyName;
          muser.lastActivityDate = new Date();
          muser.password = null;
          muser.id = user.id;
          muser.operates = user.operates;
          localStorage.setItem("muser", JSON.stringify(muser));
          return muser;
        });
      })
      .then((muser) => {
        // get permissions
        return PermissionService.getPermissions()
          .then((permissions) => {
            // disconnect users that shouldn't have access to administration
            if (
              Object.values(permissions).find(
                (permission) => permission === "access_admin"
              ) === "access_admin"
            ) {
              return { muser, permissions };
            } else {
              return Promise.reject("Not authorized");
            }
          })
          .then((result) => {
            localStorage.setItem(
              "permissions",
              JSON.stringify(result.permissions)
            );
            return result;
          })
          .catch(() => {
            RequestService.logout();
            self.logout();
          });
      })
      .then((result) => {
        // solidary => get structures
        if (
          getEnv("VUE_APP_SOLIDARY") === "true" &&
          Object.values(result.permissions).find(
            (permission) => permission === "structure_list"
          ) === "structure_list"
        ) {
          return SolidaryStructureService.getItems()
            .then((response) => {result.muser.solidaryStructures = response.items
              .map((structure) => ({
                  id: structure.id,
                  name: structure.name,
                  telephone: structure.telephone,
                  signature: structure.signature,
                  solidaryTransport: structure.solidaryTransport,
                }));
              result.muser.currentSolidaryStructure =
                result.muser.solidaryStructures.length > 0
                  ? result.muser.solidaryStructures[0].id
                  : null;
              localStorage.setItem("muser", JSON.stringify(result.muser));
              return result;
            })
            .catch(() => {
              RequestService.logout();
              self.logout();
            });
        }
        // no solidary or no structures for the current user
        return result;
      })
      .then((result) => {
        // solidary => get external journey providers
        if (
          getEnv("VUE_APP_SOLIDARY") === "true" &&
          Object.values(result.permissions).find(
            (permission) => permission === "structure_list"
          ) === "structure_list"
        ) {
          return ExternalJourneysService.getExternalJourneyProviders()
            .then((response) => {
              result.externalJourneyProviders = response.items.map(
                (externalJourneyProvider) => ({
                  name: externalJourneyProvider.name,
                })
              );

              localStorage.setItem(
                "externalJourneyProviders",
                JSON.stringify(result.externalJourneyProviders)
              );
              return result;
            })
            .catch(() => {
              RequestService.logout();
              self.logout();
            });
        }
        // no solidary or no structures for the current user
        return result;
      })
      .then((result) => {
        return PlatformService.getEECAvailable().then(response => {
          result.muser.eecAvailable = false;
          if(response.available){
            result.muser.eecAvailable = response.available;
          }
          
           return result;
          }).catch( () => {
            RequestService.logout();
            self.logout();
        });
      })      
      .then((result) => {
        return result;
      })
      .catch();
  }

  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("permissions");
    localStorage.removeItem("muser");
    localStorage.removeItem("externalJourneyProviders");
    localStorage.removeItem("eecAvailable");
  }
}

export default new AuthService();
