import SolidaryStructureService from '../services/solidaryStructure.service';
import MessageService from '../services/message.service';
import { initialState, defaultActions, defaultMutations, defaultGetters } from './common.module';

export const solidaryStructureModule = {
  namespaced:true,
  state: initialState(),  // mix with custom state if needed
  mutations: {
    ...defaultMutations()
    // add custom mutations
  },
  getters: {
    ...defaultGetters()
    // add custom getters
  },
  actions: {
    ...defaultActions(),
    createItem({commit}, fields ) {
      let item = {};
      fields.fields.forEach( field => {
        item[field] = null;
      });
      // add custom fields
      item.needs = [];
      item.subjects = [];
      item.structureProofsRequester = [];
      item.structureProofsVolunteer = [];
      item.operators = [];
      item.mMinTime = '06:00';
      item.mMaxTime = '12:00';
      item.aMinTime = '12:00';
      item.aMaxTime = '18:00';
      item.eMinTime = '18:00';
      item.eMaxTime = '23:00';
      commit('SAVE_ITEM', item);
    },
    loadItems({commit, state}) {
      commit('SAVE_LOADING', true);
      SolidaryStructureService.getItems(state.options.sortBy, state.options.sortDesc, state.options.page, state.options.itemsPerPage, state.filter).then(response => {
        // check if current options are still valid : last load may have changed the number of items, so current page may not be valid for example
        let nbpages = Math.ceil(response.total/state.options.itemsPerPage);
        if (nbpages<state.options.page) {
          let newOptions = state.options;
          if (nbpages>0) {
            newOptions.page = nbpages;
          } else {
            newOptions.page = 1;
          }
          commit('SAVE_OPTIONS',newOptions);
        }
        commit('SAVE_ITEMS', response);
      }).catch( () => {
        commit('SAVE_ERROR', true);
      }).finally( () => {
        commit('SAVE_LOADING', false);
      });
    },
    loadItem({commit, state}, { id, fields} ) {
      // clear item if given id is different than current id
      if (state.item && state.item.id && id != state.item.id) {
        commit('SAVE_ITEM', null);
      }
      commit('SAVE_LOADING', true);
      SolidaryStructureService.getItem(id).then(response => {
        // complete the item with potential nullable fields that were not retrieved
        fields.forEach( item => {
          if (response[item] === undefined) {
            if (item === 'operators') {
              // 'operators' needs to be an array
              response[item] = [];  
            } else {
              response[item] = null;
            }
          }
        });
        commit('SAVE_ITEM', response);
      }).catch( () => {
        MessageService.error('loaded','solidarystructure');
        commit('SAVE_ERROR', true);
      }).finally( () => {
        commit('SAVE_LOADING', false);
      });
    },
    postItem( { commit }, item) {
      commit('SAVE_LOADING', true);
      SolidaryStructureService.postItem(item).then( response => {
        commit('SAVE_ITEM', response);
        MessageService.success('created','solidarystructure');
      }).catch( () => {
        MessageService.error('created','solidarystructure');
        commit('SAVE_ERROR', true);
      }).finally( () => {
        commit('SAVE_LOADING', false);
      });
    },
    patchItem({commit}, data) {
      const { id, item } = data;
      commit('SAVE_LOADING', true);
      SolidaryStructureService.patchItem(id, item).then(response => {
        commit('SAVE_ITEM', response);
        MessageService.success('updated','solidarystructure');
      }).catch( () => {
        MessageService.error('updated','solidarystructure');
        commit('SAVE_ERROR', true);
      }).finally( () => {
        commit('SAVE_LOADING', false);
      });
    },
    deleteItem({commit}, id) {
      commit('SAVE_LOADING', true);
      SolidaryStructureService.deleteItem(id).then( () => {
        MessageService.success('deleted','solidarystructure');
        commit('SAVE_ITEM', null);
      }).catch( () => {
        MessageService.error('deleted','solidarystructure');
        commit('SAVE_ERROR', true);
      }).finally( () => {
        commit('SAVE_LOADING', false);
      });
    }
  }
};
