// uncomment if needed
// import RequestService from './request.service';
import { getItems } from './common.service';

class KibanaLoginService {
  route = 'admin/kibana_logins';
  getItems(sortBy, sortDesc, page, perPage, filter) {
    return getItems(this.route, sortBy, sortDesc, page, perPage, filter).then( response => Promise.resolve(response), error => Promise.reject(error) );
  };
}

export default new KibanaLoginService();