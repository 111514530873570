import AuthService from '../services/auth.service';

let muser = {};
let permissions = {};

try {
  const parsedUser = JSON.parse(localStorage.getItem('muser'));
  if (parsedUser && parsedUser.id && parsedUser.givenName && parsedUser.shortFamilyName && parsedUser.email) {
    muser = parsedUser;
  } else {
    muser = false;
    localStorage.removeItem('muser');
  }
} catch (e) {
  muser = false;
  localStorage.removeItem('muser');
}

try {
  const parsedPermissions = JSON.parse(localStorage.getItem('permissions'));
  if (parsedPermissions) permissions = parsedPermissions;
} catch (e) {
  permissions = false;
  localStorage.removeItem('permissions');
  muser = false;
  localStorage.removeItem('muser');
}

const initialState = muser
  ? { loggedIn: true , muser: muser, permissions: Object.values(permissions) }
  : { loggedIn: false , muser: null, permissions: [] };

initialState.darkTheme = JSON.parse(localStorage.getItem('dark'));

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, muser) {
      return AuthService.login(muser).then(
        (result) => {
          commit('loginSuccess', result);
          return Promise.resolve(result.muser);
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      AuthService.logout();
      commit('logout');
    },
    setDarkTheme({ commit }, dark) {
      commit('darkTheme',dark);
    },
    setCurrentSolidaryStructure({ commit }, structure) {
      commit('currentSolidaryStructure',structure);
    },
    setSolidaryEdit({ commit }, edit) {
      commit('solidaryEdit',edit);
    }
  },
  mutations: {
    loginSuccess(state, result) {
      state.loggedIn = true;
      state.muser = result.muser;
      state.permissions = Object.values(result.permissions);
    },
    loginFailure(state) {
      state.loggedIn = false;
      state.muser = null;
      state.permissions = [];
    },
    logout(state) {
      state.loggedIn = false;
      state.muser = null;
      state.permissions = [];
    },
    darkTheme(state, dark) {
      state.darkTheme = dark;
      localStorage.setItem('dark', JSON.stringify(dark));
    },
    currentSolidaryStructure(state, structure) {
      state.muser.currentSolidaryStructure = structure;
      localStorage.setItem('muser', JSON.stringify(state.muser));
    },
    solidaryEdit(state, edit) {
      state.muser.solidaryEdit = edit;
    }
  },
  getters: {
    loggedIn (state) {
      return state.loggedIn;
    },
    darkTheme (state) {
      return state.darkTheme;
    },
    id (state) {
      return state.muser.id;
    },
    email (state) {
      return state.muser.email;
    },
    authorized: (state) => (item) => {
      return state.permissions.find(permission => permission === item) === item;
    }
  }
};
