let relayPointTypeRoutes = [
  {
    path: '/relaypointtypes',
    name: 'relaypointtypes',
    meta: {
      permission: 'relay_point_type_manage',
      requiresAuth: true,
      parent: 'settings',
      show: 'relaypointtype_show',
      edit: 'relaypointtype_edit',
      create: 'relaypointtype_create',
      sub: true,
      menu: 1,
      icon: 'mdi-alpha-t-circle-outline'
    },
    component: () =>
      import(/* webpackChunkName: "relaypointtypes" */ '../views/RelayPointTypes.vue')
  },
  {
    path: '/relaypointtypes/create',
    name: 'relaypointtype_create',
    meta: {
      permission: 'relay_point_type_create',
      requiresAuth: true,
      parent: 'relaypointtypes',
      icon: 'mdi-alpha-t-circle-outline'
    },
    props: {
      edit: false
    },
    component: () =>
      import(/* webpackChunkName: "relaypointtypes" */ '../views/RelayPointTypes.vue')
  },
  {
    path: '/relaypointtypes/show/:id',
    name: 'relaypointtype_show',
    meta: {
      permission: 'relay_point_type_read',
      requiresAuth: true,
      parent: 'relaypointtypes',
      edit: 'relaypointtype_edit',
      icon: 'mdi-alpha-t-circle-outline'
    },
    component: () =>
      import(/* webpackChunkName: "relaypointtypes" */ '../views/RelayPointTypes.vue')
  },
  {
    path: '/relaypointtypes/:id',
    name: 'relaypointtype_edit',
    meta: {
      permission: 'relay_point_type_update',
      requiresAuth: true,
      parent: 'relaypointtypes',
      icon: 'mdi-alpha-t-circle-outline'
    },
    props: {
      edit: true
    },
    component: () =>
      import(/* webpackChunkName: "relaypointtypes" */ '../views/RelayPointTypes.vue')
  }
];

export { relayPointTypeRoutes };