import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import {fr, en} from 'vuetify/es5/locale';
import getEnv from '@/utils/env';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: {fr, en},
    current: 'fr',
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: getEnv('VUE_APP_THEME_LIGHT_PRIMARY'),
        secondary: getEnv('VUE_APP_THEME_LIGHT_SECONDARY'),
        accent: getEnv('VUE_APP_THEME_LIGHT_ACCENT'),
        error: getEnv('VUE_APP_THEME_LIGHT_ERROR'),
        info: getEnv('VUE_APP_THEME_LIGHT_INFO'),
        warning: getEnv('VUE_APP_THEME_LIGHT_WARNING'),
        success: getEnv('VUE_APP_THEME_LIGHT_SUCCESS')
      }
    }
  }
});
