import AnalyticService from '../services/analytic.service';
import MessageService from '../services/message.service';
import { initialState, defaultActions, defaultMutations, defaultGetters } from './common.module';

export const analyticModule = {
  namespaced:true,
  state: {
    ...initialState(),
    periodicity: null
  },
  mutations: {
    ...defaultMutations(),
    SAVE_PERIODICITY(state, periodicity) {
      state.periodicity = periodicity;
    },
  },
  getters: {
    ...defaultGetters(),
    periodicity (state) {
      return state.periodicity;
    },
    // add custom getters
  },
  actions: {
    ...defaultActions(),
    loadItems({commit, state}) {
      commit('SAVE_LOADING', true);
      AnalyticService.getItems(state.options.sortBy, state.options.sortDesc, state.options.page, state.options.itemsPerPage, state.filter).then(response => {
        let nbpages = Math.ceil(response.total/state.options.itemsPerPage);
        if (nbpages<state.options.page) {
          let newOptions = state.options;
          if (nbpages>0) {
            newOptions.page = nbpages;
          } else {
            newOptions.page = 1;
          }
          commit('SAVE_OPTIONS',newOptions);
        }
        commit('SAVE_ITEMS', response);
      }).catch( () => {
        commit('SAVE_ERROR', true);
      }).finally( () => {
        commit('SAVE_LOADING', false);
      });
    },
    loadItem({commit}, { id, periodicity, territoryId, communityId, forceDefaultCommunityId, forceDefaultTerritoryId, filter} ) {
      commit('SAVE_LOADING', true);
      AnalyticService.getItem(id, periodicity, territoryId, communityId, forceDefaultCommunityId, forceDefaultTerritoryId, filter).then(response => {
        commit('SAVE_ITEM', response);
      }).catch( () => {
        MessageService.error('loaded','analytic');
        commit('SAVE_ERROR', true);
      }).finally( () => {
        commit('SAVE_LOADING', false);
      });
    },
    changePeriodicity({commit}, periodicity){
      commit('SAVE_PERIODICITY', periodicity);
    }
  }
};
