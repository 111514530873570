import RequestService from './request.service';
import { getItems } from './common.service';

class AnalyticService {
  route = 'admin/analytics';
  getItems(sortBy, sortDesc, page, perPage, filter) {
    return getItems(this.route, sortBy, sortDesc, page, perPage, filter).then( response => Promise.resolve(response), error => Promise.reject(error) );
  }
  getItem(id, periodicity, territoryId, communityId, forceDefaultCommunityId, forceDefaultTerritoryId, filter=null) {
    if (typeof id === 'undefined') {
      return RequestService
        .get(this.route, params, 'json')
        .then( response => Promise.resolve(response.data), error => Promise.reject(error) );
    }

    let params = {};
    if(filter){
      for (const [key, value] of Object.entries(filter)) {
        if (value != '' && value !== null) {
          params[key] = value;
        }
      }
    }
    
    let darkTheme = 0;
    if(JSON.parse(localStorage.getItem('dark'))){
      darkTheme = 1;
    } else {
      darkTheme = 0;
    }

    let request_url = `${this.route}/${(id ? id : 'summary')}`;
    request_url += `?periodicity=${(periodicity ? periodicity : 'monthly')}`;
    request_url += `&darkTheme=${(darkTheme ? darkTheme : 0)}`;
    request_url += `&communityId=${(communityId ? communityId : null)}`;
    request_url += `&territoryId=${(territoryId ? territoryId : null)}`;

    request_url += `&forceDefaultCommunityId=${(forceDefaultCommunityId ? 1 : 0)}`;
    request_url += `&forceDefaultTerritoryId=${(forceDefaultTerritoryId ? 1 : 0)}`;

    return RequestService
      .get(request_url, params, 'json')
      .then( response => Promise.resolve(response.data), error => Promise.reject(error) );
  }
}

export default new AnalyticService();