// uncomment if needed
// import RequestService from './request.service';
import { getItems, getItem, patchItem, deleteItem } from './common.service';

class SolidaryVolunteerService {
  route = 'admin/solidary_volunteers';
  getItems(sortBy, sortDesc, page, perPage, filter) {
    return getItems(this.route, sortBy, sortDesc, page, perPage, filter).then( response => Promise.resolve(response), error => Promise.reject(error) );
  };
  getItem(id) {
    return getItem(this.route,id)
      .then( response => {
        response.availabilities = this.formatAvailabilities(response);
        return Promise.resolve(response);
      }
      )
      .catch( error => Promise.reject(error) );
  };
  patchItem(id,item) {
    // remove unwanted properties : diaries, proofs, structures
    delete item.diaries;
    delete item.proofs;
    delete item.structures;

    if(item.availabilities){
      item.availabilities.forEach(element => {
        item['m'+element.day.charAt(0).toUpperCase()+element.day.slice(1)] = element.mAvailable;
        item['a'+element.day.charAt(0).toUpperCase()+element.day.slice(1)] = element.aAvailable;
        item['e'+element.day.charAt(0).toUpperCase()+element.day.slice(1)] = element.eAvailable;
      });      
    }  

    delete item.availabilities;

    return patchItem(this.route, id, item)
      .then( response => {
        response.availabilities = this.formatAvailabilities(response);
        return Promise.resolve(response);
      }
      )
      .catch( error => Promise.reject(error) );

  };
  deleteItem(id) {
    return deleteItem(this.route, id).then( response => Promise.resolve(response), error => Promise.reject(error) );
  };
  formatAvailabilities(item) {
    return [
      { day: 'mon', mAvailable: item.mMon, aAvailable: item.aMon, eAvailable: item.eMon },
      { day: 'tue', mAvailable: item.mTue, aAvailable: item.aTue, eAvailable: item.eTue },
      { day: 'wed', mAvailable: item.mWed, aAvailable: item.aWed, eAvailable: item.eWed },
      { day: 'thu', mAvailable: item.mThu, aAvailable: item.aThu, eAvailable: item.eThu },
      { day: 'fri', mAvailable: item.mFri, aAvailable: item.aFri, eAvailable: item.eFri },
      { day: 'sat', mAvailable: item.mSat, aAvailable: item.aSat, eAvailable: item.eSat },
      { day: 'sun', mAvailable: item.mSun, aAvailable: item.aSun, eAvailable: item.eSun }
    ];
  }
}

export default new SolidaryVolunteerService();