let solidaryStructureRoutes = [
  {
    path: '/solidarystructures',
    name: 'solidarystructures',
    meta: {
      permission: 'structure_list',
      requiresAuth: true,
      parent: 'solidary',
      show: 'solidarystructure_show',
      edit: 'solidarystructure_edit',
      create: 'solidarystructure_create',
      sub: true,
      menu: 4,
      icon: 'mdi-home-city'
    },
    component: () =>
      import(/* webpackChunkName: "solidarystructures" */ '../views/SolidaryStructures.vue')
  },
  {
    path: '/solidarystructures/create',
    name: 'solidarystructure_create',
    meta: {
      permission: 'structure_create',
      requiresAuth: true,
      parent: 'solidarystructures',
      icon: 'mdi-home-city'
    },
    props: {
      edit: false
    },
    component: () =>
      import(/* webpackChunkName: "solidarystructures" */ '../views/SolidaryStructures.vue')
  },
  {
    path: '/solidarystructures/show/:id',
    name: 'solidarystructure_show',
    meta: {
      permission: 'structure_read',
      requiresAuth: true,
      parent: 'solidarystructures',
      edit: 'solidarystructure_edit',
      icon: 'mdi-home-city'
    },
    component: () =>
      import(/* webpackChunkName: "solidarystructures" */ '../views/SolidaryStructures.vue')
  },
  {
    path: '/solidarystructures/:id',
    name: 'solidarystructure_edit',
    meta: {
      permission: 'structure_update',
      requiresAuth: true,
      parent: 'solidarystructures',
      icon: 'mdi-home-city'
    },
    props: {
      edit: true
    },
    component: () =>
      import(/* webpackChunkName: "solidarystructures" */ '../views/SolidaryStructures.vue')
  }
];

export { solidaryStructureRoutes };