import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import './plugins/tiptap-vuetify';
import i18n from './plugins/i18n';
import { store } from './store';
import GlobalPageTitle from './mixins/GlobalPageTitle';

Vue.config.productionTip = false;
Vue.mixin(GlobalPageTitle);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app');
