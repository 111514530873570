// service that makes the requests to the API
// all other request services use this service

import axios from 'axios';
import qs from 'qs';
import moment from 'moment';
import router from '../router';
import { store } from '../store';
import getEnv from '@/utils/env';
import jwt_decode from 'jwt-decode';
import MessageService from './message.service';

class RequestService {
  get(route,params,type='json',checkToken=true,checkIdle=true) {
    let self = this;
    // check if token exists and is still valid (not for login style requests)
    return this.tokenValid(checkToken)
      .then( () => {
        return this.idleTimeExceeded(checkIdle)
          .catch( error => {
            // idle time exceeded
            MessageService.error(error);
            this.logout();
            return Promise.reject(error);
          });
      })
      .then( () => {
        return axios
          .get(getEnv('VUE_APP_API_URL') + route, { 
            headers: this.getHeaders(type), 
            params: params,
            paramsSerializer: function (params) {
              return qs.stringify(params, {arrayFormat: 'brackets'});
            },
          })
          .then( response => {
            self.updateLastActivityDate();
            return response;
          })
          .catch( error => {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx 
              switch (error.response.status) {
              case 400:
                // bad request
                if (error.response.data && error.response.data['hydra:description']) {
                  MessageService.error(error.response.data['hydra:description']);
                  return Promise.reject(error.response.data['hydra:description']);
                }
                break;
              case 401: 
                //  unauthorized
                if (error.response.data.message == 'Expired JWT Token') {
                  // check refreshToken
                  return self.refreshToken()
                    .then( () => {
                      // try again !
                      return self.get(route,params,type);
                    })
                    .catch( error => {
                      // time limit exceeded or unvalid refreshToken
                      MessageService.error(error);
                      this.logout();
                      return Promise.reject(error);
                    });
                } else {
                  // permission denied, we don't logout
                  MessageService.error(error.response.data.message);
                  return Promise.reject(error.response.data.message);
                }
              case 500:
                // internal server error
                MessageService.error(error.message);
                return Promise.reject(error.message);
              }
            } else if (error.request) {
              // The request was made but no response was received
              MessageService.error(error.message);
              return Promise.reject(error.message);
            } else {
              // Something happened in setting up the request that triggered an Error
              MessageService.error(error.message);
              return Promise.reject(error.message);
            }
          });
      })
      .catch( error => Promise.reject(error) );
  };
  post(route,data,params,type='json',checkToken=true,checkIdle=true) {
    let self = this;
    // check if token exists and is still valid (not for login style requests)
    return this.tokenValid(checkToken)
      .then( () => {
        return this.idleTimeExceeded(checkIdle)
          .catch( error => {
            MessageService.error(error);
            this.logout();
            return Promise.reject(error);
          });
      })
      .then( () => {
        return axios
          .post(getEnv('VUE_APP_API_URL') + route, data, { 
            headers: this.getHeaders(type), 
            params: params,
            paramsSerializer: function (params) {
              return qs.stringify(params, {arrayFormat: 'brackets'});
            },
          })
          .then( response => {
            self.updateLastActivityDate();
            // if (route !== 'login') {
            //   MessageService.success('Post success');
            // }
            return response;
          })
          .catch( error => {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx 
              switch (error.response.status) {
              case 400:
                // bad request
                if (error.response.data && error.response.data['hydra:description']) {
                  MessageService.error(error.response.data['hydra:description']);
                  return Promise.reject(error.response.data['hydra:description']);
                } else if (error.response.data && error.response.data.detail) {
                  MessageService.error(error.response.data.detail);
                  return Promise.reject(error.response.data.detail);
                }
                break;
              case 401: 
                //  unauthorized
                if (error.response.data.message == 'Expired JWT Token') {
                  // check refreshToken
                  return self.refreshToken()
                    .then( () => {
                      // try again !
                      return self.post(route,data,params,type);
                    })
                    .catch( error => {
                      // time limit exceeded or unvalid refreshToken
                      MessageService.error(error);
                      this.logout();
                      return Promise.reject(error);
                    });
                } else {
                  // wrong credentials, permission denied
                  MessageService.error(error.response.data.message);
                  return Promise.reject(error.response.data.message);
                }
              case 500:
                // internal server error
                MessageService.error(error.message);
                return Promise.reject(error.message);
              }
            } else if (error.request) {
              // The request was made but no response was received
              MessageService.error(error.message);
              return Promise.reject(error.message);
            } else {
              // Something happened in setting up the request that triggered an Error
              MessageService.error(error.message);
              return Promise.reject(error.message);
            }
          });
      })
      .catch( error => Promise.reject(error) );
  };
  put(route,data,params,type='json',checkToken=true,checkIdle=true) {
    let self = this;
    // check if token exists and is still valid (not for login style requests)
    return this.tokenValid(checkToken)
      .then( () => {
        return this.idleTimeExceeded(checkIdle)
          .catch( error => {
            MessageService.error(error);
            this.logout();
            return Promise.reject(error);
          });
      })
      .then( () => {
        return axios
          .put(getEnv('VUE_APP_API_URL') + route, JSON.stringify(data), { 
            headers: this.getHeaders(type), 
            params: params,
            paramsSerializer: function (params) {
              return qs.stringify(params, {arrayFormat: 'brackets'});
            },
          })
          .then( response => {
            self.updateLastActivityDate();
            MessageService.success('Put success');
            return response;
          })
          .catch( error => {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx 
              switch (error.response.status) {
              case 400:
                // bad request
                if (error.response.data && error.response.data['hydra:description']) {
                  MessageService.error(error.response.data['hydra:description']);
                  return Promise.reject(error.response.data['hydra:description']);
                } else if (error.response.data && error.response.data.detail) {
                  MessageService.error(error.response.data.detail);
                  return Promise.reject(error.response.data.detail);
                }
                break;
              case 401: 
                //  unauthorized
                if (error.response.data.message == 'Expired JWT Token') {
                  // check refreshToken
                  return self.refreshToken()
                    .then( () => {
                      // try again !
                      return self.put(route,data,params,type);
                    })
                    .catch( error => {
                      // time limit exceeded or unvalid refreshToken
                      MessageService.error(error);
                      this.logout();
                      return Promise.reject(error);
                    });
                } else if (error.response.data.message == 'Invalid credentials') {
                  // invalid credentials
                  MessageService.error(error.response.data.message);
                  return Promise.reject(error.response.data.message);
                } else {
                  // permission denied
                  MessageService.error(error.response.data.message);
                  return Promise.reject(error.response.data.message);
                }
              case 500:
                // internal server error
                MessageService.error(error.message);
                return Promise.reject(error.message);
              }
            } else if (error.request) {
              // The request was made but no response was received
              MessageService.error(error.message);
              return Promise.reject(error.message);
            } else {
              // Something happened in setting up the request that triggered an Error
              MessageService.error(error.message);
              return Promise.reject(error.message);
            }
          });
      })
      .catch( error => Promise.reject(error) );
  };
  patch(route,data,params,type='json-patch',checkToken=true,checkIdle=true) {
    let self = this;
    // check if token exists and is still valid (not for login style requests)
    return this.tokenValid(checkToken)
      .then( () => {
        return this.idleTimeExceeded(checkIdle)
          .catch( error => {
            MessageService.error(error);
            this.logout();
            return Promise.reject(error);
          });
      })
      .then( () => {
        return axios
          .patch(getEnv('VUE_APP_API_URL') + route, JSON.stringify(data), { 
            headers: this.getHeaders(type), 
            params: params,
            paramsSerializer: function (params) {
              return qs.stringify(params, {arrayFormat: 'brackets'});
            },
          })
          .then( response => {
            self.updateLastActivityDate();
            return response;
          })
          .catch( error => {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx 
              switch (error.response.status) {
              case 400:
                // bad request
                if (error.response.data && error.response.data['hydra:description']) {
                  MessageService.error(error.response.data['hydra:description']);
                  return Promise.reject(error.response.data['hydra:description']);
                } else if (error.response.data && error.response.data.detail) {
                  MessageService.error(error.response.data.detail);
                  return Promise.reject(error.response.data.detail);
                }
                break;
              case 401: 
                //  unauthorized
                if (error.response.data.message == 'Expired JWT Token') {
                  // check refreshToken
                  return self.refreshToken()
                    .then( () => {
                      // try again !
                      return self.patch(route,data,params,type);
                    })
                    .catch( error => {
                      // time limit exceeded or unvalid refreshToken
                      MessageService.error(error);
                      this.logout();
                      return Promise.reject(error);
                    });
                } else if (error.response.data.message == 'Invalid credentials') {
                  // invalid credentials
                  MessageService.error(error.response.data.message);
                  return Promise.reject(error.response.data.message);
                } else {
                  // permission denied
                  MessageService.error(error.response.data.message);
                  return Promise.reject(error.response.data.message);
                }
              case 500:
                // internal server error
                MessageService.error(error.message);
                return Promise.reject(error.message);
              }
            } else if (error.request) {
              // The request was made but no response was received
              MessageService.error(error.message);
              return Promise.reject(error.message);
            } else {
              // Something happened in setting up the request that triggered an Error
              MessageService.error(error.message);
              return Promise.reject(error.message);
            }
          });
      })
      .catch( error => Promise.reject(error) );
  };
  delete(route,params,type='json',checkToken=true,checkIdle=true) {
    let self = this;
    // check if token exists and is still valid (not for login style requests)
    return this.tokenValid(checkToken)
      .then( () => {
        return this.idleTimeExceeded(checkIdle)
          .catch( error => {
            MessageService.error(error);
            this.logout();
            return Promise.reject(error);
          });
      })
      .then( () => {
        return axios
          .delete(getEnv('VUE_APP_API_URL') + route, { 
            headers: this.getHeaders(type), 
            params: params,
            paramsSerializer: function (params) {
              return qs.stringify(params, {arrayFormat: 'brackets'});
            },
          })
          .then( response => {
            self.updateLastActivityDate();
            MessageService.success('Delete success');
            return response;
          })
          .catch( error => {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx 
              switch (error.response.status) {
              case 400:
                // bad request
                if (error.response.data && error.response.data['hydra:description']) {
                  MessageService.error(error.response.data['hydra:description']);
                  return Promise.reject(error.response.data['hydra:description']);
                }
                break;
              case 401: 
                //  unauthorized
                if (error.response.data.message == 'Expired JWT Token') {
                  // check refreshToken
                  return self.refreshToken()
                    .then( () => {
                      // try again !
                      return self.delete(route,params,type);
                    })
                    .catch( error => {
                      // time limit exceeded or unvalid refreshToken
                      MessageService.error(error);
                      this.logout();
                      return Promise.reject(error);
                    });
                } else if (error.response.data.message == 'Invalid credentials') {
                  // invalid credentials
                  MessageService.error(error.response.data.message);
                  return Promise.reject(error.response.data.message);
                } else {
                  // permission denied
                  MessageService.error(error.response.data.message);
                  return Promise.reject(error.response.data.message);
                }
              case 500:
                // internal server error
                MessageService.error(error.message);
                return Promise.reject(error.message);
              }
            } else if (error.request) {
              // The request was made but no response was received
              MessageService.error(error.message);
              return Promise.reject(error.message);
            } else {
              // Something happened in setting up the request that triggered an Error
              MessageService.error(error.message);
              return Promise.reject(error.message);
            }
          });
      })
      .catch( error => Promise.reject(error) );
  };
  getHeaders(type) {
    const token = JSON.parse(localStorage.getItem('token'));
    if (token) {
      switch (type) {
      case 'hydra':
        return { Authorization: 'Bearer ' + token };
      case 'json':
        return { Authorization: 'Bearer ' + token, Accept: 'application/json', 'Content-Type': 'application/json'};
      case 'json-normalized':
        return { Authorization: 'Bearer ' + token, Accept: '*/*', 'Content-Type': 'application/json'};
      case 'json-patch':
        return { Authorization: 'Bearer ' + token, Accept: 'application/json', 'Content-Type': 'application/merge-patch+json'};
      case 'file':
        return { Authorization: 'Bearer ' + token, Accept: 'application/json', 'Content-Type': 'multipart/form-data'};
      case 'csv':
        return { Authorization: 'Bearer ' + token, Accept: '*/*', 'Content-Type': 'text/csv'};
      }
    } else {
      return {};
    }
  };
  refreshToken() {
    let self = this;
    const refreshToken = JSON.parse(localStorage.getItem('refreshToken'));        
    return axios
      .post(getEnv('VUE_APP_API_URL') + 'token/refresh', { refreshToken: refreshToken },{ headers: this.getHeaders('json') })
      .then( response => {
        if (response.data.token) {
          localStorage.setItem('token', JSON.stringify(response.data.token));
        } else {
          return Promise.reject('Token not found in response');
        }
        if (response.data.refreshToken) {
          localStorage.setItem('refreshToken', JSON.stringify(response.data.refreshToken));
        } else {
          return Promise.reject('Refresh token not found in response');
        }
        return Promise.resolve();
      })
      .catch( error => {
        self.logout();
        return Promise.reject(error);
      });
    
  };
  logout() {
    store.dispatch('auth/logout');
    router.push('/login').catch(()=>{});
  };
  updateLastActivityDate() {
    let muser = JSON.parse(localStorage.getItem('muser'));
    if (muser !== null) {
      muser.lastActivityDate = new Date();
      localStorage.setItem('muser', JSON.stringify(muser));
    }
  };
  tokenValid(checkToken) {
    if (checkToken) {
      const token = JSON.parse(localStorage.getItem('token'));
      if (token === undefined) {
        // no token !
        return Promise.reject('No token');
      }
      const decodedToken = jwt_decode(token);
      if (Date.now() >= decodedToken.exp * 1000) {
        // token is expired => refresh token
        return this.refreshToken();
      }
    }
    return Promise.resolve();    
  };
  idleTimeExceeded(checkIdle) {
    if (checkIdle) {
      let muser = JSON.parse(localStorage.getItem('muser'));
      if (muser === undefined) {
        return Promise.reject('User not logged');
      }
      let lastActivityDate = moment(muser.lastActivityDate);
      if (moment().diff(lastActivityDate,'seconds')>getEnv('VUE_APP_SESSION_DURATION')) {
        return Promise.reject('Idle time exceeded');
      };
    }
    return Promise.resolve();
  };
  success(message) {
    store.dispatch('message',{
      text: message,
      timeout: 5000,
      color: 'green'
    });
  };
  error(error) {
    store.dispatch('message',{
      text: error,
      timeout: 8000,
      color: 'red'
    });
  };
}

export default new RequestService();