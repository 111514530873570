let editorialRoutes = [
  {
    path: '/editorials',
    name: 'editorials',
    meta: {
      permission: 'editorial_manage',
      requiresAuth: true,
      parent: 'home',
      show: 'editorial_show',
      edit: 'editorial_edit',
      create: 'editorial_create',
      menu: 9,
      icon: 'mdi-book-edit'
    },
    component: () =>
      import(/* webpackChunkName: "editorials" */ '../views/Editorials.vue')
  },
  {
    path: '/editorials/create',
    name: 'editorial_create',
    meta: {
      permission: 'editorial_create',
      requiresAuth: true,
      parent: 'editorials',
      icon: 'mdi-book-edit'
    },
    props: {
      edit: false
    },
    component: () =>
      import(/* webpackChunkName: "editorials" */ '../views/Editorials.vue')
  },
  {
    path: '/editorials/show/:id',
    name: 'editorial_show',
    meta: {
      permission: 'editorial_read',
      requiresAuth: true,
      parent: 'editorials',
      edit: 'editorial_edit',
      icon: 'mdi-book-edit'
    },
    component: () =>
      import(/* webpackChunkName: "editorials" */ '../views/Editorials.vue')
  },
  {
    path: '/editorials/:id',
    name: 'editorial_edit',
    meta: {
      permission: 'editorial_update',
      requiresAuth: true,
      parent: 'editorials',
      icon: 'mdi-book-edit'
    },
    props: {
      edit: true
    },
    component: () =>
      import(/* webpackChunkName: "editorials" */ '../views/Editorials.vue')
  }
];

export { editorialRoutes };