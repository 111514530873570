// uncomment if needed
// import RequestService from './request.service';
import { getItems, getItem, postItem, patchItem, deleteItem } from './common.service';

class SolidaryStructureService {
  route = 'admin/structures';
  getItems(sortBy, sortDesc, page, perPage, filter) {
    return getItems(this.route, sortBy, sortDesc, page, perPage, filter).then( response => Promise.resolve(response), error => Promise.reject(error) );
  };
  getItem(id) {
    return getItem(this.route,id)
      .then(response => {
        Object.keys(response).map( function(key) {
          // keep only the time part for min and max times
          if (/Time$/.test(key) && response[key]) {
            response[key] = response[key].split('T')[1].substr(0,5);
          }
          // keep only the ids of territories
          if (key === 'territories') {
            response[key] = response[key].map( element => element.id);
          }
          // change mode from int to boolean
          if (key == 'mode') {
            response[key] = response[key] == 1 ? true : false;
          }
          // split proofs into requester / volunteer proofs and add input type direct field
          let structureProofsRequester = [];
          let structureProofsVolunteer = [];
          if (key === 'structureProofs') {
            response[key].forEach(element => {
              if (element.checkbox !== undefined && element.checkbox) {
                element.inputType = 'checkbox';
              } else if (element.input !== undefined && element.input) {
                element.inputType = 'input';
              } else if (element.selectbox !== undefined && element.selectbox) {
                element.inputType = 'selectbox';
              } else if (element.radio !== undefined && element.radio) {
                element.inputType = 'radio';
              } else if (element.file !== undefined && element.file) {
                element.inputType = 'file';
              }
              if (element.type == 1) {
                structureProofsRequester.push({...element});
              } else {
                structureProofsVolunteer.push({...element});
              }
            });
            response.structureProofsRequester = structureProofsRequester;
            response.structureProofsVolunteer = structureProofsVolunteer;
          }
          if (key === 'solidaryTransport') {
            response[key] = !response[key];
          }
        });
        return Promise.resolve(response);
      })
      .catch( error => Promise.reject(error) );
  };
  postItem(item) {
    Object.keys(item).map( function(key) {
      // transform times to datetimes
      if (/Time$/.test(key) && item[key]) {
        item[key] = '1970-01-01T'+item[key]+':00+00:00';
      }
      // change mode from boolean to int
      if (key == 'mode') {
        item[key] = item[key] == true ? 1 : 0;
      }
      if (key === 'solidaryTransport') {
        item[key] = !item[key];
      }
    });
    
    item.structureProofs = [...item.structureProofsRequester,...item.structureProofsVolunteer];
    item.structureProofs.map( function(element) {
      element.input = false;
      element.checkbox = false;
      element.selectbox = false;
      element.radio = false;
      element.file = false;
      if (element.inputType == 'input') {
        element.input = true;
      } else if (element.inputType == 'checkbox') {
        element.checkbox = true;
      } else if (element.inputType == 'selectbox') {
        element.selectbox = true;
      } else if (element.inputType == 'radio') {
        element.radio = true;
      } else if (element.inputType == 'file') {
        element.file = true;
      } 
    });
    return postItem(this.route, item)
      .then(response => {
        Object.keys(response).map( function(key) {
          // keep only the time part for min and max times
          if (/Time$/.test(key) && response[key]) {
            response[key] = response[key].split('T')[1].substr(0,5);
          }
          // change mode from int to boolean
          if (key == 'mode') {
            response[key] = response[key] == 1 ? true : false;
          }
          if (key === 'solidaryTransport') {
            response[key] = !response[key];
          }
        });
        return Promise.resolve(response);
      })
      .catch( error => Promise.reject(error) );
  };
  patchItem(id,item) {
    Object.keys(item).map( function(key) {
      // transform times to datetimes
      if (/Time$/.test(key) && item[key]) {
        item[key] = '1970-01-01T'+item[key]+':00+00:00';
      }
      // change mode from boolean to int
      if (key == 'mode') {
        item[key] = item[key] == true ? 1 : 0;
      }
      if (key === 'solidaryTransport') {
        item[key] = !item[key];
      }
    });
    if(item.structureProofsRequester){
      item.structureProofsRequester.map( function(element) {
        element.input = false;
        element.checkbox = false;
        element.selectbox = false;
        element.radio = false;
        element.file = false;
        if (element.inputType == 'input') {
          element.input = true;
        } else if (element.inputType == 'checkbox') {
          element.checkbox = true;
        } else if (element.inputType == 'selectbox') {
          element.selectbox = true;
        } else if (element.inputType == 'radio') {
          element.radio = true;
        } else if (element.inputType == 'file') {
          element.file = true;
        } 
      });
    }
    if(item.structureProofsVolunteer){
      item.structureProofsVolunteer.map( function(element) {
        element.input = false;
        element.checkbox = false;
        element.selectbox = false;
        element.radio = false;
        element.file = false;
        if (element.inputType == 'input') {
          element.input = true;
        } else if (element.inputType == 'checkbox') {
          element.checkbox = true;
        } else if (element.inputType == 'selectbox') {
          element.selectbox = true;
        } else if (element.inputType == 'radio') {
          element.radio = true;
        } else if (element.inputType == 'file') {
          element.file = true;
        } 
      });
    }
    return patchItem(this.route, id, item)
      .then(response => {
        Object.keys(response).map( function(key) {
          // keep only the time part for min and max times
          if (/Time$/.test(key) && response[key]) {
            response[key] = response[key].split('T')[1].substr(0,5);
          }
          // keep only the ids of territories
          if (key === 'territories') {
            response[key] = response[key].map( element => element.id);
          }
          // change mode from int to boolean
          if (key == 'mode') {
            response[key] = response[key] == 1 ? true : false;
          }
          // split proofs into requester / volunteer proofs and add input type direct field
          let structureProofsRequester = [];
          let structureProofsVolunteer = [];
          if (key === 'structureProofs') {
            response[key].forEach(element => {
              if (element.checkbox !== undefined && element.checkbox) {
                element.inputType = 'checkbox';
              } else if (element.input !== undefined && element.input) {
                element.inputType = 'input';
              } else if (element.selectbox !== undefined && element.selectbox) {
                element.inputType = 'selectbox';
              } else if (element.radio !== undefined && element.radio) {
                element.inputType = 'radio';
              } else if (element.file !== undefined && element.file) {
                element.inputType = 'file';
              }
              if (element.type == 1) {
                structureProofsRequester.push({...element});
              } else {
                structureProofsVolunteer.push({...element});
              }
            });
            response.structureProofsRequester = structureProofsRequester;
            response.structureProofsVolunteer = structureProofsVolunteer;
          }
          if (key === 'solidaryTransport') {
            response[key] = !response[key];
          }
        });
        return Promise.resolve(response);
      })
      .catch( error => Promise.reject(error) );
  }
  deleteItem(id) {
    return deleteItem(this.route, id).then( response => Promise.resolve(response), error => Promise.reject(error) );
  }
}

export default new SolidaryStructureService();