<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-4">
        <h1 class="text-h3 font-weight-bold mb-3">
          {{ title }}
        </h1>
        <p>{{ description }}</p>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col>
        <div class="text-h4 font-weight-medium">
          {{ $t('welcome') }}
        </div>
        <!-- start of kibana informations 1/2 -->
        <div v-if="displayKibana == 'true'">
          <v-btn
            class="mt-12"
            color="green"
            :href="scopeDashboards"
            target="_blank"
          >
            {{ $t('button.label') }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <!-- start of kibana informations 2/2 -->
    <div v-if="displayKibana == 'true'">
      <v-row
        v-if="kibanaLogins"
        align="center"
        justify="center"
      >
        <v-col
          v-for="kibanaLogin in kibanaLogins"
          :key="kibanaLogin.username"
          align="center"
          justify="center"
        >
          <p>
            {{ $t('kibanaLogin', { login : kibanaLogin.username }) }}
          </p>
          <p>
            {{ $t('kibanaPassword', { password : kibanaLogin.password }) }}
          </p>
        </v-col>
      </v-row>
    </div>
    <v-row v-if="isRzpAdminBtnDisplayed" class="text-center">
      <v-col>
        <v-btn
          class="mt-12"
          color="green"
          :href="rzpAdminLink"
          target="_blank"
        >
          {{ $t('rzp-button.label') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import getEnv from '@/utils/env';
import {messages_components_en, messages_components_fr} from '@/translations/components/MobiHome/';
import KibanaLoginService from '@/services/kibanaLogin.service';

export default {
  name: 'MobiHome',
  i18n: {
    messages: {
      'en': messages_components_en,
      'fr': messages_components_fr
    }
  },
  data() {
    return {
      scopeDashboards: getEnv('VUE_APP_SCOPE_URL'),
      kibanaLogins: []
    };
  },
  computed: {
    displayKibana() {
      var kibana = getEnv('VUE_APP_DISPLAY_KIBANA');
      return kibana;
    },
    dashboardType() {
      if (this.$store.getters['auth/authorized']('dashboard_read')) {
        return 1;
      }
      if (this.$store.getters['auth/authorized']('community_dashboard_self')) {
        return 2;
      }
      return null;
    },
    title() {
      return getEnv('VUE_APP_MOBIHOME_TITLE');
    },
    description() {
      return getEnv('VUE_APP_MOBIHOME_DESCRIPTION');
    },
    isRzpAdminBtnDisplayed() {
      return getEnv('VUE_APP_DISPLAY_ADMIN_BTN') === 'true';
    },
    rzpAdminLink() {
      return getEnv('VUE_APP_RZP_ADMIN_URL');
    }
  },
  mounted() {
    this.$store.commit('SET_BREADCRUMB',
      [
        {
          text: this.$t('breadcrumb.home'),
          disabled: true
        }
      ]
    );
    KibanaLoginService.getItems()
      .then(response => {
        this.kibanaLogins = response.items;
        return Promise.resolve();
      })
      .catch( () => Promise.reject());
  } 
};
</script>