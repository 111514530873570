<template>
  <div class="home">
    <MobiHome />
  </div>
</template>

<script>
import MobiHome from '@/components/MobiHome.vue';

export default {
  name: 'Home',
  components: {
    MobiHome
  }
};
</script>
