import getEnv from '@/utils/env';
import { solidaryBeneficiaryRoutes } from './solidaryBeneficiary';
import { solidaryVolunteerRoutes } from './solidaryVolunteer';
import { solidaryRecordRoutes } from './solidaryRecord';
import { solidaryStructureRoutes } from './solidaryStructure';
import { solidaryAnalyticRoutes } from './solidaryAnalytic';

let solidaryRoutes = [
  {
    path: '/solidary',
    skip: true,
    name: 'solidary',
    meta: {
      permission: [
        "solidary_beneficiary_create",
        "solidary_beneficiary_delete",
        "solidary_beneficiary_list",
        "solidary_beneficiary_manage",
        "solidary_beneficiary_read",
        "solidary_beneficiary_update",
        "solidary_delete_operator",
        "solidary_delete",
        "solidary_list_operator",
        "solidary_list",
        "solidary_manager_read",
        "solidary_read_operator",
        "solidary_read",
        "solidary_update_operator",
        "solidary_update",
        "solidary_user_structure_create",
        "solidary_user_structure_delete",
        "solidary_user_structure_list",
        "solidary_user_structure_manage",
        "solidary_user_structure_read",
        "solidary_user_structure_update",
        "solidary_volunteer_create",
        "solidary_volunteer_delete",
        "solidary_volunteer_list",
        "solidary_volunteer_manage",
        "solidary_volunteer_read",
        "solidary_volunteer_update",
        "structure_create",
        "structure_delete",
        "structure_list_operator",
        "structure_manage",
        "structure_read_operator",
        "structure_read",
        "structure_update_operator"
      ],
      parent: 'home',
      menu: 1,
      icon: 'mdi-handshake',
      subs: 'solidary'
    },
  },
  ...solidaryBeneficiaryRoutes,
  ...(getEnv('VUE_APP_SOLIDARY_TRANSPORT') === 'true' ? solidaryVolunteerRoutes : []),
  ...solidaryRecordRoutes,
  ...solidaryStructureRoutes,
  ...solidaryAnalyticRoutes
];

export { solidaryRoutes };