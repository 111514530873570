// uncomment if needed
// import RequestService from './request.service';
import { postFile } from './common.service';

class ImportService {
  route = 'admin/imports';
  postFile(data, resource) {
    return postFile(`${this.route}/${resource}`, data).then( response => Promise.resolve(response), error => Promise.reject(error) );
  }
}

export default new ImportService();