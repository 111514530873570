// uncomment if needed
// import RequestService from './request.service';
import {  postItem } from './common.service';

class ScammerService {
  route = 'admin/scammers';
  
  postItem(item) {
    return postItem(this.route, item).then( response => Promise.resolve(response), error => Promise.reject(error) );
  };
}
export default new ScammerService();