let campaignRoutes = [
  {
    path: '/campaigns',
    name: 'campaigns',
    meta: {
      permission: 'campaign_list',
      requiresAuth: true,
      parent: 'home',
      show: 'campaign_show',
      edit: 'campaign_edit',
      create: 'campaign_create',
      menu: 5,
      icon: 'mdi-email-multiple'
    },
    component: () =>
      import(/* webpackChunkName: "campaigns" */ '../views/Campaigns.vue')
  },
  {
    path: '/campaigns/create',
    name: 'campaign_create',
    meta: {
      permission: 'campaign_create',
      requiresAuth: true,
      parent: 'campaigns',
      icon: 'mdi-email-multiple'
    },
    props: {
      edit: false
    },
    component: () =>
      import(/* webpackChunkName: "campaigns" */ '../views/Campaigns.vue')
  },
  {
    path: '/campaigns/show/:id',
    name: 'campaign_show',
    meta: {
      permission: 'campaign_read',
      requiresAuth: true,
      parent: 'campaigns',
      edit: 'campaign_edit',
      icon: 'mdi-email-multiple'
    },
    component: () =>
      import(/* webpackChunkName: "campaigns" */ '../views/Campaigns.vue')
  },
  {
    path: '/campaigns/:id',
    name: 'campaign_edit',
    meta: {
      permission: 'campaign_update',
      requiresAuth: true,
      parent: 'campaigns',
      icon: 'mdi-email-multiple'
    },
    props: {
      edit: true
    },
    component: () =>
      import(/* webpackChunkName: "campaigns" */ '../views/Campaigns.vue')
  },
  {
    path: '/campaigns/send/:id',
    name: 'campaign_send',
    meta: {
      permission: 'campaign_update',
      requiresAuth: true,
      parent: 'campaigns',
      icon: 'mdi-email-multiple'
    },
    props: {
      edit: true
    },
    component: () =>
      import(/* webpackChunkName: "campaigns" */ '../views/Campaigns.vue')
  }
];

export { campaignRoutes };