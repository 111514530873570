let communityRoutes = [
  {
    path: '/communities',
    name: 'communities',
    meta: {
      permission: 'community_manage',
      requiresAuth: true,
      parent: 'home',
      show: 'community_show',
      edit: 'community_edit',
      create: 'community_create',
      importUsers: 'community_users_import',
      menu: 6,
      icon: 'mdi-account-group'
    },
    component: () =>
      import(/* webpackChunkName: "communities" */ '../views/Communities.vue')
  },
  {
    path: '/communities/create',
    name: 'community_create',
    meta: {
      permission: 'from_admin_community_create',
      requiresAuth: true,
      parent: 'communities',
      icon: 'mdi-account-group'
    },
    props: {
      edit: false
    },
    component: () =>
      import(/* webpackChunkName: "communities" */ '../views/Communities.vue')
  },
  {
    path: '/communities/show/:id',
    name: 'community_show',
    meta: {
      permission: 'community_read',
      requiresAuth: true,
      parent: 'communities',
      edit: 'community_edit',
      dashboard: 'community_dashboard',
      icon: 'mdi-account-group'
    },
    component: () =>
      import(/* webpackChunkName: "communities" */ '../views/Communities.vue')
  },
  {
    path: '/communities/:id',
    name: 'community_edit',
    meta: {
      permission: 'community_update',
      requiresAuth: true,
      parent: 'communities',
      icon: 'mdi-account-group'
    },
    props: {
      edit: true
    },
    component: () =>
      import(/* webpackChunkName: "communities" */ '../views/Communities.vue')
  },
  {
    path: '/communities/users/import',
    name: 'community_users_import',
    meta: {
      permission: 'user_create',
      requiresAuth: true,
      parent: 'communities',
      icon: 'mdi-alpha-p-circle-outline'
    },
    props: {
      edit: false
    },
    component: () =>
      import(/* webpackChunkName: "communities" */ '../views/Communities.vue')
  },
  {
    path: '/communities/:communityId/dashboard',
    name: 'community_dashboard',
    meta: {
      permission: 'community_read',
      requiresAuth: true,
      parent: 'communities',
      icon: 'mdi-alpha-p-circle-outline'
    },
    component: () =>
      import(/* webpackChunkName: "communities" */ '../views/Analytics.vue')
  },    
];

export { communityRoutes };