// uncomment if needed
import RequestService from './request.service';
import { postItem, patchItem, deleteItem } from './common.service';

class CommunityMemberService {
  route = 'admin/community_members';
  postItem(item) {
    return postItem(this.route, item).then( response => Promise.resolve(response), error => Promise.reject(error));
  };
  patchItem(id,item) {
    return patchItem(this.route, id, item).then( response => Promise.resolve(response), error => Promise.reject(error));
  };
  deleteItem(id) {
    return deleteItem(this.route, id).then( response => Promise.resolve(response), error => Promise.reject(error) );
  };
  associate(campaign,filterType,filter,deliveries=[],community) {
    let params = {
      campaignId: campaign,
      filterType: filterType,
      communityId: community
    };
    for (const [key, value] of Object.entries(filter)) {
      if (value != '' && value !== null) {
        params[key] = value;
      }
    }
    if (deliveries.length>0) {
      params['member'] = deliveries;
    }
    return RequestService
      .get(this.route+'/associate-campaign', params, 'hydra')
      .then( response => Promise.resolve(response), error => Promise.reject(error) );
  };
  sendCampaign(campaign,mode,filters) {
    let params = {
      campaignId: campaign,
      mode: mode
    };
    // filters is an array of { key, values } where values is also an array
    // we have to convert the values to a single element if there's only one value 
    // and let them as array if there are multiple values
    // this way it will be correctly added to the query string
    filters.forEach(element => {
      if (element.values.length>1) {
        params[element.key] = element.values;
      } else if (element.values[0] != '' && element.values[0] !== null) {
        params[element.key] = element.values[0];
      }      
    });
    return RequestService
      .get(this.route+'/send-campaign', params, 'hydra')
      .then( response => Promise.resolve(response), error => Promise.reject(error) );
  };
}

export default new CommunityMemberService();