let solidaryBeneficiaryRoutes = [
  {
    path: '/solidarybeneficiaries',
    name: 'solidarybeneficiaries',
    meta: {
      permission: 'solidary_beneficiary_list',
      requiresAuth: true,
      parent: 'solidary',
      show: 'solidarybeneficiary_show',
      edit: 'solidarybeneficiary_edit',
      sub: true,
      menu: 1,
      icon: 'mdi-walk'
    },
    component: () =>
      import(/* webpackChunkName: "solidarybeneficiaries" */ '../views/SolidaryBeneficiaries.vue')
  },
  {
    path: '/solidarybeneficiaries/show/:id',
    name: 'solidarybeneficiary_show',
    meta: {
      permission: 'solidary_beneficiary_read',
      requiresAuth: true,
      parent: 'solidarybeneficiaries',
      edit: 'solidarybeneficiary_edit',
      icon: 'mdi-walk'
    },
    component: () =>
      import(/* webpackChunkName: "solidarybeneficiaries" */ '../views/SolidaryBeneficiaries.vue')
  },
  {
    path: '/solidarybeneficiaries/:id',
    name: 'solidarybeneficiary_edit',
    meta: {
      permission: 'solidary_beneficiary_update',
      requiresAuth: true,
      parent: 'solidarybeneficiaries',
      icon: 'mdi-walk'
    },
    props: {
      edit: true
    },
    component: () =>
      import(/* webpackChunkName: "solidarybeneficiaries" */ '../views/SolidaryBeneficiaries.vue')
  }
];

export { solidaryBeneficiaryRoutes };