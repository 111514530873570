// uncomment if needed
// import RequestService from './request.service';
import { getItems, getItem, postItem, patchItem, deleteItem, postFile } from './common.service';

class SolidaryRecordService {
  route = 'admin/solidaries';
  proofFileRoute = 'admin/proofs';
  actionsRoute = 'admin/solidaries/actions';
  externalJourneysRoute = 'external_journeys';
  getItems(sortBy, sortDesc, page, perPage, filter) {
    return getItems(this.route, sortBy, sortDesc, page, perPage, filter)
      .then( response => {
        response.items.map( function (item) {
          Object.keys(item).map( function(key) {
            // rewrite keys to remove 'admin'
            if (/^admin/.test(key) && item[key]) {
              delete Object.assign(item, {[key.substr(5)]: item[key] })[key];
            }
          });
        });
        return Promise.resolve(response);
      })
      .catch( error => Promise.reject(error) );
  };
  getItem(id) {
    return getItem(this.route,id)
      .then(response => {
        Object.keys(response).map( function(key) {
          // rewrite keys to remove 'admin'
          if (/^admin/.test(key) && response[key] !== undefined) {
            delete Object.assign(response, {[key.substr(5)]: response[key] })[key];
          }
        });
        if(response['solutions'] && response['solutions']['drivers']){
          response['solutions']['drivers'].forEach(element => {
            element['availabilities'] = this.formatAvailabilities(element);
          });
        }
        return Promise.resolve(response);
      })
      .catch( error => Promise.reject(error) );
  };
  postItem(item) {
    // we need to rewrite some properties to keep compatibility on API side
    // keep only structure id
    item.structure = item.structure.id;
    // rewrite subject to adminsubject if not an additional subject
    if (item.subject != 9999) {
      item.adminsubject = item.subject;
    }
    delete item.subject;
    // cut birthdate 
    if (item.beneficiary && item.beneficiary.birthDate) {
      item.beneficiary.birthDate = item.beneficiary.birthDate.substring(0,10);
    }
    // rewrite schedules
    if (Array.isArray(item.regularSchedules) && item.regularSchedules.length>0) {
      item.regularSchedules = item.regularSchedules.map( schedule => (
        {
          outwardTime: schedule.outwardTime,
          returnTime: schedule.returnTime,
          mon: schedule.days.find( element => element === 'mon') === 'mon',
          tue: schedule.days.find( element => element === 'tue') === 'tue',
          wed: schedule.days.find( element => element === 'wed') === 'wed',
          thu: schedule.days.find( element => element === 'thu') === 'thu',
          fri: schedule.days.find( element => element === 'fri') === 'fri',
          sat: schedule.days.find( element => element === 'sat') === 'sat',
          sun: schedule.days.find( element => element === 'sun') === 'sun'
        }
      ));
    }
    return postItem(this.route, item).then( response => Promise.resolve(response), error => Promise.reject(error) );
  };
  patchItem(id,item) {
    // we need to rewrite some properties to keep compatibility on API side
    // rewrite subject to adminsubject if not an additional subject
    if (item.subject != 9999) {
      item.adminsubject = item.subject;
    }
    delete item.subject;
    // cut birthdate 
    if (item.beneficiary && item.beneficiary.birthDate) {
      item.beneficiary.birthDate = item.beneficiary.birthDate.substring(0,10);
    }
    // rewrite schedules
    if (Array.isArray(item.regularSchedules) && item.regularSchedules.length>0) {
      item.regularSchedules = item.regularSchedules.map( schedule => (
        {
          outwardTime: schedule.outwardTime,
          returnTime: schedule.returnTime,
          mon: schedule.days.find( element => element === 'mon') === 'mon',
          tue: schedule.days.find( element => element === 'tue') === 'tue',
          wed: schedule.days.find( element => element === 'wed') === 'wed',
          thu: schedule.days.find( element => element === 'thu') === 'thu',
          fri: schedule.days.find( element => element === 'fri') === 'fri',
          sat: schedule.days.find( element => element === 'sat') === 'sat',
          sun: schedule.days.find( element => element === 'sun') === 'sun'
        }
      ));
    }
    return patchItem(this.route, id, item)
      .then(response => {
        Object.keys(response).map( function(key) {
        // rewrite keys to remove 'admin'
          if (/^admin/.test(key) && response[key]) {
            delete Object.assign(response, {[key.substr(5)]: response[key] })[key];
          }
        });
        response.updated = true;
        return Promise.resolve(response);
      })
      .catch( error => Promise.reject(error) );
  }
  deleteItem(id) {
    return deleteItem(this.route, id).then( response => Promise.resolve(response), error => Promise.reject(error) );
  }
  postProofFile(item) {
    return postFile(this.proofFileRoute, item).then( response => Promise.resolve(response), error => Promise.reject(error) );
  }
  getActions() {
    return getItems(this.actionsRoute).then( response => Promise.resolve(response), error => Promise.reject(error) );
  }
  formatAvailabilities(item) {
    return [
      { day: 'mon', mAvailable: item.mMon, aAvailable: item.aMon, eAvailable: item.eMon },
      { day: 'tue', mAvailable: item.mTue, aAvailable: item.aTue, eAvailable: item.eTue },
      { day: 'wed', mAvailable: item.mWed, aAvailable: item.aWed, eAvailable: item.eWed },
      { day: 'thu', mAvailable: item.mThu, aAvailable: item.aThu, eAvailable: item.eThu },
      { day: 'fri', mAvailable: item.mFri, aAvailable: item.aFri, eAvailable: item.eFri },
      { day: 'sat', mAvailable: item.mSat, aAvailable: item.aSat, eAvailable: item.eSat },
      { day: 'sun', mAvailable: item.mSun, aAvailable: item.aSun, eAvailable: item.eSun }
    ];
  }
}

export default new SolidaryRecordService();