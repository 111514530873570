import RequestService from './request.service';

function getItems(route, sortBy, sortDesc, page, perPage, filter = {}) {
  let sort = {};      
  if (Array.isArray(sortBy) && Array.isArray(sortDesc)) {
    sortBy.forEach(function (element, key) {
      sort[element] = sortDesc[key] === true ? 'desc' : 'asc';
    });
  }
  let params = {
    order: sort,
    page: page,
    perPage: perPage
  };
  for (const [key, value] of Object.entries(filter)) {
    if (value != '' && value !== null) {
      params[key] = value;
    }
  }
  // uncomment to allow properties filter
  // the property list should be on the store
  // let properties = ['givenName','familyName'];
  // properties.forEach(function(property) {
  //   params['properties[]'] = property;
  // });
  return RequestService
    .get(route, params, 'hydra')
    .then(response => {
      return Promise.resolve({
        items: response.data['hydra:member'],
        total: response.data['hydra:totalItems']
      });
    })
    .catch( error => {
      return Promise.reject(error);
    });
}

function getItem(route, id, type='json') {
  return RequestService
    .get(route+'/'+id, {}, type)
    .then( response => Promise.resolve(response.data), error => Promise.reject(error) );
};

function getSpecialItem(route, id, special) {
  return RequestService
    .get(route+'/'+id+'/'+special, {}, 'json')
    .then( response => Promise.resolve(response.data), error => Promise.reject(error) );
};

function postItem(route, item) {
  return RequestService
    .post(route, item, {}, 'json')
    .then( response => Promise.resolve(response.data), error => Promise.reject(error) );
};

function putItem(route, id, item) {
  return RequestService
    .put(route+'/'+id, item, {}, 'json')
    .then( response => Promise.resolve(response.data), error => Promise.reject(error) );
};

function patchItem(route, id, item) {
  return RequestService
    .patch(route+'/'+id, item, {}, 'json-patch')
    .then( response => Promise.resolve(response.data), error => Promise.reject(error) );
};

function deleteItems(route, parameters) {
  return RequestService
    .delete(`${route}?${parameters}`)
    .then( response => Promise.resolve(response.data), error => Promise.reject(error) );
}

function deleteItem(route, id) {
  return RequestService
    .delete(route+'/'+id)
    .then( response => Promise.resolve(response.data), error => Promise.reject(error) );
}

function postFile(route, item) {
  return RequestService
    .post(route, item, {}, 'file')
    .then( response => Promise.resolve(response.data), error => Promise.reject(error) );
};

export {getItems, getItem, getSpecialItem, postItem, putItem, patchItem, deleteItems, deleteItem, postFile};
