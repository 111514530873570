let eventRoutes = [
  {
    path: '/events',
    name: 'events',
    meta: {
      permission: 'event_manage',
      requiresAuth: true,
      parent: 'home',
      show: 'event_show',
      edit: 'event_edit',
      create: 'event_create',
      menu: 7,
      icon: 'mdi-calendar-range'
    },
    component: () =>
      import(/* webpackChunkName: "events" */ '../views/Events.vue')
  },
  {
    path: '/events/create',
    name: 'event_create',
    meta: {
      permission: 'event_create',
      requiresAuth: true,
      parent: 'events',
      icon: 'mdi-calendar-range'
    },
    props: {
      edit: false
    },
    component: () =>
      import(/* webpackChunkName: "events" */ '../views/Events.vue')
  },
  {
    path: '/events/show/:id',
    name: 'event_show',
    meta: {
      permission: 'event_read',
      requiresAuth: true,
      parent: 'events',
      edit: 'event_edit',
      icon: 'mdi-calendar-range'
    },
    component: () =>
      import(/* webpackChunkName: "events" */ '../views/Events.vue')
  },
  {
    path: '/events/:id',
    name: 'event_edit',
    meta: {
      permission: 'event_update',
      requiresAuth: true,
      parent: 'events',
      icon: 'mdi-calendar-range'
    },
    props: {
      edit: true
    },
    component: () =>
      import(/* webpackChunkName: "events" */ '../views/Events.vue')
  }
];

export { eventRoutes };