import IdentityProofService from '../services/identityProof.service';
import MessageService from '../services/message.service';
import { initialState, defaultActions, defaultMutations, defaultGetters } from './common.module';

export const identityProofModule = {
  namespaced:true,
  state: initialState(),
  mutations: {
    ...defaultMutations()
  },
  getters: {
    ...defaultGetters()
  },
  actions: {
    ...defaultActions(),
    postItem( {commit}, item) {
      commit('SAVE_LOADING', true);
      IdentityProofService.postItem(item).then( response => {
        commit('SAVE_ITEM', response);
        MessageService.success('created','identityProof');
      }).catch( () => {
        MessageService.error('created','identityProof');
        commit('SAVE_ERROR', true);
      }).finally( () => {
        commit('SAVE_LOADING', false);
      });
    },
    patchItem({commit}, data) {
      const { id, item } = data;
      commit('SAVE_LOADING', true);
      IdentityProofService.patchItem(id, item).then( () => {
        MessageService.success('updated','identityProof');
      }).catch( () => {
        MessageService.error('updated','identityProof');
        commit('SAVE_ERROR', true);
      }).finally( () => {
        commit('SAVE_LOADING', false);
      });
    },
  }
};
