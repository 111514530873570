export const EXPORT_EXTENSION = 'csv';
export const PREFIX = new Date().toJSON().slice(0, 10);
export const SUFFIX = 'export';

class CsvExport
{
    exportToCsv(filename, fileContent) {
        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(fileContent);
        anchor.target = '_blank';
        anchor.download = `${PREFIX}-${filename}_${SUFFIX}.${EXPORT_EXTENSION}`;
        anchor.click();
    }
}

export default new CsvExport();