import EventService from '../services/event.service';
import MessageService from '../services/message.service';
import { initialState, defaultActions, defaultMutations, defaultGetters } from './common.module';

export const eventModule = {
  namespaced:true,
  state: initialState(),  // mix with custom state if needed
  mutations: {
    ...defaultMutations()
    // add custom mutations
  },
  getters: {
    ...defaultGetters()
    // add custom getters
  },
  actions: {
    ...defaultActions(),
    loadItems({commit, state}, primary=true) {
      commit('SAVE_LOADING', true);
      // replace here by the real service
      EventService.getItems(state.options.sortBy, state.options.sortDesc, state.options.page, state.options.itemsPerPage, state.filter).then(response => {
        if (primary) {
          // this load concerns the main component => it's not a load to populate a subcomponent, we need to check the options
          // check if current options are still valid : last load may have changed the number of items, so current page may not be valid for example
          let nbpages = Math.ceil(response.total/state.options.itemsPerPage);
          if (nbpages<state.options.page) {
            let newOptions = state.options;
            if (nbpages>0) {
              newOptions.page = nbpages;
            } else {
              newOptions.page = 1;
            }
            commit('SAVE_OPTIONS',newOptions);
          }
        }
        commit('SAVE_ITEMS', response);
      }).catch( () => {
        commit('SAVE_ERROR', true);
      }).finally( () => {
        commit('SAVE_LOADING', false);
      });
    },
    loadItem({commit, state}, { id, fields} ) {
      // clear item if given id is different than current id
      if (state.item && state.item.id && id != state.item.id) {
        commit('SAVE_ITEM', null);
      }
      commit('SAVE_LOADING', true);
      // replace here by the real service
      EventService.getItem(id).then(response => {
        // complete the item with potential nullable fields that were not retrieved
        fields.forEach( item => {
          if (response[item] === undefined) {
            response[item] = null;
          }
        });
        // add required fields for image management
        response.newImage = null;
        response.resetImage = false;
        commit('SAVE_ITEM', response);
      }).catch( () => {
        MessageService.error('loaded','event');
        commit('SAVE_ERROR', true);
      }).finally( () => {
        commit('SAVE_LOADING', false);
      });
    },
    postItem({commit}, item) {
      commit('SAVE_LOADING', true);
      EventService.postItem(item).then(response => {
        // check for a new image 
        if (item.newImage) {
          let formData = new FormData();
          formData.append('eventId', response.id);
          formData.append('eventFile', item.newImage);
          formData.append('position', 1);
          EventService.postImage(formData).then( image => {
            let completedResponse = {
              ...response,
              newImage: null,
              resetImage: false, // no need to reset the image field, we will redirect to the event list
              image: image.image,
              avatar: image.avatar
            };
            commit('SAVE_ITEM', completedResponse);
            MessageService.success('created','event');
          }).catch( () => {
            MessageService.error('created','event');
          });
        } else {
          commit('SAVE_ITEM', response);
          MessageService.success('created','event');
        }
      }).catch( () => {
        MessageService.error('created','event');
        commit('SAVE_ERROR', true);
      }).finally( () => {
        commit('SAVE_LOADING', false);
      });
    },
    patchItem({commit}, data) {
      const { id, item } = data;
      commit('SAVE_LOADING', true);
      EventService.patchItem(id, item).then(response => {
        // check for a new image 
        if (item.newImage) {
          let formData = new FormData();
          formData.append('eventId', id);
          formData.append('eventFile', item.newImage);
          formData.append('position', 1);
          EventService.postImage(formData).then( image => {
            let completedResponse = {
              ...response,
              newImage: null,
              resetImage: true, // to clear the new image in the image field component
              image: image.image,
              avatar: image.avatar
            };
            commit('SAVE_ITEM', completedResponse);
            MessageService.success('updated','event');
          }).catch( () => {
            MessageService.error('updated','event');
            commit('SAVE_ERROR', true);
          });
        } else {
          // add required fields for image management
          response.newImage = null;
          response.resetImage = false;
          commit('SAVE_ITEM', response);
          MessageService.success('updated','event');
        }
      }).catch( () => {
        MessageService.error('updated','event');
        commit('SAVE_ERROR', true);
      }).finally( () => {
        commit('SAVE_LOADING', false);
      });
    },
    deleteItem({commit}, id) {
      commit('SAVE_LOADING', true);
      EventService.deleteItem(id).then( () => {
        MessageService.success('deleted','event');
        commit('SAVE_ITEM', null);
      }).catch( () => {
        MessageService.error('deleted','event');
        commit('SAVE_ERROR', true);
      }).finally( () => {
        commit('SAVE_LOADING', false);
      });
    }
  }
};
