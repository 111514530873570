let hitchHikerRoutes = [
  {
    path: '/hitchhikers',
    name: 'hitchhikers',
    meta: {
      permission: 'hitchhiking_watcher',
      requiresAuth: true,
      parent: 'home',
      show: 'hitchhiker_show',
      edit: 'hitchhiker_edit',
      create: 'hitchhiker_create',
      dashboard: 'hitchhikers_dashboard',
      menu: 4,
      icon: 'mdi-thumb-up'
    },
    component: () =>
      import(/* webpackChunkName: "hitchHikers" */ '../views/HitchHikers.vue')
  },
  {
    path: '/hitchhikers/create',
    name: 'hitchhiker_create',
    meta: {
      permission: 'hitchhiking_administrator',
      requiresAuth: true,
      parent: 'hitchhikers',
      icon: 'mdi-thumb-up'
    },
    props: {
      edit: false
    },
    component: () =>
      import(/* webpackChunkName: "hitchHikers" */ '../views/HitchHikers.vue')
  },
  {
    path: '/hitchhikers/show/:id',
    name: 'hitchhiker_show',
    meta: {
      permission: 'hitchhiking_watcher',
      requiresAuth: true,
      parent: 'hitchhikers',
      edit: 'hitchhiker_edit',
      icon: 'mdi-thumb-up'
    },
    component: () =>
      import(/* webpackChunkName: "hitchHikers" */ '../views/HitchHikers.vue')
  },
  {
    path: '/hitchhikers/dashboard',
    name: 'hitchhikers_dashboard',
    meta: {
      permission: 'hitchhiking_watcher',
      requiresAuth: true,
      parent: 'home',
      icon: 'mdi-alpha-p-circle-outline'
    },
    component: () =>
      import(/* webpackChunkName: "hitchHikers" */ '../views/Analytics.vue')
  },
  {
    path: '/hitchhikers/:id',
    name: 'hitchhiker_edit',
    meta: {
      permission: 'hitchhiking_administrator',
      requiresAuth: true,
      parent: 'hitchhikers',
      icon: 'mdi-thumb-up'
    },
    props: {
      edit: true
    },
    component: () =>
      import(/* webpackChunkName: "hitchHikers" */ '../views/HitchHikers.vue')
  },

  
];

export { hitchHikerRoutes };