<template>
  <div>
    <!-- navigation menu -->
    <v-navigation-drawer v-model="drawer" app>
      <!-- logo -->
      <v-list-item>
        <v-list-item-content>
          <v-img :src="logo" />
        </v-list-item-content>
      </v-list-item>

      <!-- title -->
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            {{ navigationDrawerTitle }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ navigationDrawerSubtitle }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider />

      <!-- menu -->
      <v-list dense nav>
        <v-list-item
          v-for="item in menuItems"
          :key="item.title"
          :to="item.href"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <!-- solidary -->
      <v-divider v-if="solidaryItems.length > 0" />
      <v-list v-if="solidaryItems.length > 0" dense nav>
        <v-list-group
          v-for="item in solidaryItems"
          :key="item.title"
          :prepend-icon="item.icon"
          color=""
          :value="isRouteOfAncestor('solidary')"
        >
          <template v-slot:activator>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </template>
          <v-list-item
            v-for="subItem in item.items"
            :key="subItem.title"
            :to="subItem.href"
          >
            <v-list-item-icon>
              <v-icon>{{ subItem.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ subItem.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>

      <!-- settings -->
      <v-divider v-if="settingsItems.length > 0" />
      <v-list v-if="settingsItems.length > 0" dense nav>
        <v-list-group
          v-for="item in settingsItems"
          :key="item.title"
          :prepend-icon="item.icon"
          color=""
          :value="isRouteOfAncestor('settings')"
        >
          <template v-slot:activator>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </template>
          <v-list-item
            v-for="subItem in item.items"
            :key="subItem.title"
            :to="subItem.href"
          >
            <v-list-item-icon>
              <v-icon>{{ subItem.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ subItem.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>

    <!-- top toolbar -->
    <v-app-bar app elevate-on-scroll>
      <v-app-bar-nav-icon @click="drawer = !drawer" />
      <v-toolbar-title>
        {{ toolbarTitle }}
      </v-toolbar-title>

      <v-breadcrumbs :items="breadcrumbItems" />

      <v-spacer />

      <!-- solidary structures -->
      <v-col v-if="currentUser.solidaryStructures.length > 1" cols="3">
        <v-select
          v-model="currentSolidaryStructure"
          :disabled="currentUser.solidaryEdit"
          dense
          :label="$t('solidaryStructure')"
          :items="currentUser.solidaryStructures"
          item-text="name"
          item-value="id"
          hide-details=""
        />
      </v-col>

      <v-switch
        v-model="dark"
        class="ma-2"
        hide-details
        :label="$t('toolbar.darkSwitch')"
      />

      <v-chip class="ma-2" color="primary" label>
        <v-icon left> mdi-account-circle-outline </v-icon>
        {{ currentUser.givenName }} {{ currentUser.shortFamilyName }}
      </v-chip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click.prevent="logOut">
            <v-icon>mdi-logout</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("toolbar.disconnect") }}</span>
      </v-tooltip>
    </v-app-bar>

    <!-- main -->
    <v-main>
      <v-container fluid>
        <router-view />
        <!-- alert messages -->
        <v-snackbar
          v-model="alert"
          :timeout="message.timeout"
          :color="message.color"
          rounded="pill"
        >
          {{
            message.context && message.text
              ? $t(
                  message.context +
                    "." +
                    (message.success ? "success" : "error") +
                    "." +
                    message.text
                )
              : ""
          }}

          <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="alert = false">
              {{ $t("close") }}
            </v-btn>
          </template>
        </v-snackbar>
      </v-container>
    </v-main>

    <!-- footer -->
    <v-footer app>
      <!-- -->
    </v-footer>
  </div>
</template>

<script>
import getEnv from "@/utils/env";
import { messages_routes_en, messages_routes_fr } from "@/translations/routes/";
import {
  messages_layouts_en,
  messages_layouts_fr,
} from "@/translations/layouts/Dashboard/";
import {
  messages_messages_en,
  messages_messages_fr,
} from "@/translations/messages/";
import { hasPermission } from "../services/solidary";
let messages_en = {
  ...messages_layouts_en,
  ...messages_messages_en,
  routes: { ...messages_routes_en },
};
let messages_fr = {
  ...messages_layouts_fr,
  ...messages_messages_fr,
  routes: { ...messages_routes_fr },
};

export default {
  name: "App",
  i18n: {
    messages: {
      en: messages_en,
      fr: messages_fr,
    },
  },
  data: () => ({
    drawer: true,
    user: {
      givenName: "",
      shortFamilyName: "",
    },
  }),
  computed: {
    logo() {
      return getEnv("VUE_APP_LOGO_URL");
    },
    navigationDrawerTitle() {
      return getEnv("VUE_APP_NAV_TITLE");
    },
    navigationDrawerSubtitle() {
      return getEnv("VUE_APP_NAV_SUBTITLE");
    },
    toolbarTitle() {
      return getEnv("VUE_APP_TOOLBAR_TITLE");
    },
    // create the menu items from the router routes
    availableMenuItems() {
      return (
        this.$router.options.routes
          // all routes must have a meta section
          .filter(function (route) {
            if (
              route.meta &&
              route.meta.menu !== undefined &&
              !route.meta.subs &&
              !route.meta.sub
            ) {
              return true;
            }
            return false;
          })
          .sort((a, b) => (a.meta.menu > b.meta.menu ? 1 : -1))
          .map((route) => ({
            title: this.$t("routes." + route.name + ".menu"),
            icon: route.meta.icon,
            href: route.path,
          }))
      );
    },
    // create the solidary items from the router routes
    availableSolidaryItems() {
      return (
        this.$router.options.routes
          // all routes must have a meta section
          .filter(function (route) {
            if (
              route.meta &&
              route.meta.menu !== undefined &&
              route.meta.subs === "solidary"
            ) {
              return true;
            }
            return false;
          })
          .sort((a, b) => (a.meta.menu > b.meta.menu ? 1 : -1))
          .map((route) => ({
            title: this.$t("routes." + route.name + ".menu"),
            icon: route.meta.icon,
            href: route.path,
            items: route.meta.subs ? this.getSubItems(route) : null,
          }))
      );
    },
    // create the settings items from the router routes
    availableSettingsItems() {
      return (
        this.$router.options.routes
          // all routes must have a meta section
          .filter(function (route) {
            if (
              route.meta &&
              route.meta.menu !== undefined &&
              route.meta.subs === "settings"
            ) {
              return true;
            }
            return false;
          })
          .sort((a, b) => (a.meta.menu > b.meta.menu ? 1 : -1))
          .map((route) => ({
            title: this.$t("routes." + route.name + ".menu"),
            icon: route.meta.icon,
            href: route.path,
            items: route.meta.subs ? this.getSubItems(route) : null,
          }))
      );
    },
    menuItems() {
      // return the menu items, filtered with authorizations
      return this.availableMenuItems.filter(this.authorized);
    },
    solidaryItems() {
      // return the solidary items, filtered with authorizations
      return this.availableSolidaryItems.filter(this.authorized);
    },
    settingsItems() {
      // return the settings items, filtered with authorizations
      return this.availableSettingsItems.filter(this.authorized);
    },
    breadcrumbItems() {
      return this.$store.getters.breadcrumb;
    },
    currentUser() {
      return this.$store.state.auth.muser
        ? this.$store.state.auth.muser
        : this.user;
    },
    currentSolidaryStructure: {
      get: function () {
        return this.currentUser
          ? this.currentUser.currentSolidaryStructure
          : null;
      },
      set: function (val) {
        this.$store.dispatch("auth/setCurrentSolidaryStructure", val);
      },
    },
    currentStructure() {
      if (!this.currentUser) {
        return null;
      }

      const structures = this.currentUser
        ? this.currentUser.solidaryStructures
        : [];

      return structures.length
        ? structures.find(
            (structure) =>
              structure.id === this.currentUser.currentSolidaryStructure
          )
        : null;
    },
    message() {
      return this.$store.state.message;
    },
    dark: {
      get: function () {
        const isDark = this.$store.getters["auth/darkTheme"];
        this.setDark(isDark);
        return isDark;
      },
      set: function (val) {
        this.$store.dispatch("auth/setDarkTheme", val);
      },
    },
    alert: {
      get: function () {
        return this.$store.getters.alert;
      },
      set: function () {
        this.$store.dispatch("dismissMessage");
      },
    },
  },
  methods: {
    authorized(item) {
      let routes = this.$router.options.routes;

      const route = routes.find((route) => route.path === item.href);
      if (route === undefined) {
        // route not found in the router => error
        return false;
      }
      if (route.meta && route.meta.permission === undefined) {
        // route not protected
        return true;
      }
      switch (typeof route.meta.permission) {
        case "object":
          // route protected by several rights
          for (let item in route.meta.permission) {
            if (hasPermission(route.meta.permission[item])) {
              return true;
            }
          }
          break;
        case "string":
          // route protected by a single right
          return hasPermission(route.meta.permission);
      }
      return false;
    },
    // get the subItems of a route
    getSubItems(route) {
      let self = this;
      return this.$router.options.routes
        .filter(function (croute) {
          if (
            croute.meta &&
            croute.meta.menu !== undefined &&
            croute.meta.parent === route.name &&
            self.isSubItemDisplayAllowed(croute)
          ) {
            return self.authorized({
              href: croute.path,
            });
          }
          return false;
        })
        .sort((a, b) => (a.meta.menu > b.meta.menu ? 1 : -1))
        .map((route) => ({
          title: this.$t("routes." + route.name + ".menu"),
          icon: route.meta.icon,
          href: route.path,
        }));
    },
    isRouteOfAncestor(name) {
      return this.hasAncestor(this.$route, name);
    },
    // recursive function to check the ancestor of a route
    hasAncestor(route, ancestor) {
      if (route.name === ancestor) return true;
      if (route.meta.parent) {
        const parentRoute = this.$router.options.routes.find(
          (proute) => proute.name === route.meta.parent
        );
        if (parentRoute !== undefined) {
          return this.hasAncestor(parentRoute, ancestor);
        }
      }
      return false;
    },
    logOut() {
      // tell the store about the logout
      this.$store.dispatch("auth/logout");
      // redirect to login page, we use a catch to avoid any unwanted error display in console (eg. 401 unauthorized)
      this.$router.push("/login").catch(() => {});
    },
    setDark(val) {
      this.$vuetify.theme.dark = val;
    },
    isSubItemDisplayAllowed(route) {
      if (
        (route.meta && typeof route.meta.specialDisplayRules === "undefined") ||
        (route.meta &&
          typeof route.meta.specialDisplayRules !== "undefined" &&
          !Object.keys(route.meta.specialDisplayRules).length)
      ) {
        return true;
      }

      if (
        route.meta &&
        typeof route.meta.specialDisplayRules !== "undefined" &&
        Object.keys(route.meta.specialDisplayRules).length &&
        this.currentStructure
      ) {
        for (const [key, value] of Object.entries(
          route.meta.specialDisplayRules
        )) {
          switch (key) {
            case "solidaryTransport":
              return value === this.currentStructure.solidaryTransport
                ? true
                : false;
          }
        }
      }

      return false;
    },
  },
};
</script>
