let articleRoutes = [
  {
    path: '/articles',
    name: 'articles',
    meta: {
      permission: 'article_manage',
      requiresAuth: true,
      parent: 'home',
      show: 'article_show',
      edit: 'article_edit',
      create: 'article_create',
      menu: 8,
      icon: 'mdi-book-open'
    },
    component: () =>
      import(/* webpackChunkName: "articles" */ '../views/Articles.vue')
  },
  {
    path: '/articles/create',
    name: 'article_create',
    meta: {
      permission: 'article_create',
      requiresAuth: true,
      parent: 'articles',
      icon: 'mdi-book-open'
    },
    props: {
      edit: false
    },
    component: () =>
      import(/* webpackChunkName: "articles" */ '../views/Articles.vue')
  },
  {
    path: '/articles/show/:id',
    name: 'article_show',
    meta: {
      permission: 'article_read',
      requiresAuth: true,
      parent: 'articles',
      edit: 'article_edit',
      icon: 'mdi-book-open'
    },
    component: () =>
      import(/* webpackChunkName: "articles" */ '../views/Articles.vue')
  },
  {
    path: '/articles/:id',
    name: 'article_edit',
    meta: {
      permission: 'article_update',
      requiresAuth: true,
      parent: 'articles',
      icon: 'mdi-book-open'
    },
    props: {
      edit: true
    },
    component: () =>
      import(/* webpackChunkName: "articles" */ '../views/Articles.vue')
  }
];

export { articleRoutes };