import ScammerService from '../services/scammer.service';
import MessageService from '../services/message.service';
import { initialState, defaultActions, defaultMutations, defaultGetters } from './common.module';

export const scammerModule = {
  namespaced:true,
  state: initialState(),  // mix with custom state if needed
  mutations: {
    ...defaultMutations()
    // add custom mutations
  },
  getters: {
    ...defaultGetters()
    // add custom getters
  },
  actions: {
    ...defaultActions(),
    postItem({commit}, item) {
      commit('SAVE_LOADING', true);
      ScammerService.postItem(item).then(response => {
        commit('SAVE_ITEM', response);
        MessageService.success('created','scammer');
      }).catch( () => {
        MessageService.error('created','scammer');
        commit('SAVE_ERROR', true);
      }).finally( () => {
        commit('SAVE_LOADING', false);
      });
    }
  }
};
