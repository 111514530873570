import GeosearchService from '../services/geosearch.service';

const initialState = {
  items: [],
  search: '',
  total: 0,
};

export const geosearchModule = {
  namespaced:true,
  state: initialState,
  actions: {
    clearItems({commit}) {
      commit('SAVE_ITEMS', {
        items: [],
        total:0
      });
    },
    loadItems({commit}, search) {
      commit('SAVE_SEARCH', search);
      GeosearchService.getItems(search).then(response => {
        commit('SAVE_ITEMS', response);
      }).catch();
    }
  },
  mutations: {
    SAVE_SEARCH(state, search) {
      state.search = search;
    },
    SAVE_ITEMS(state, items) {
      state.items = items.items;
      state.total = items.total;
    }
  },
  getters: {
    items (state) {
      return state.items;
    },
    search (state) {
      return state.search;
    }
  }
};
