// uncomment if needed
// import RequestService from './request.service';
import { getItems, getItem, postItem, putItem, patchItem, deleteItem, postFile } from './common.service';

class CommunityService {
  route = 'admin/communities';
  imageRoute = 'admin/images';
  fileRoute = 'admin/community_securities';
  getItems(sortBy, sortDesc, page, perPage, filter) {
    return getItems(this.route, sortBy, sortDesc, page, perPage, filter).then( response => Promise.resolve(response), error => Promise.reject(error) );
  };
  getItem(id) {
    return getItem(this.route,id).then( response => {
      // we add a newImage field to track image change
      return Promise.resolve({
        ...response,
        newImage: false
      });
    }).then( response => Promise.resolve(response), error => Promise.reject(error) );
  };
  postItem(item) {
    return postItem(this.route, item).then( response => Promise.resolve(response), error => Promise.reject(error) );
  };
  putItem(id,item) {
    return putItem(this.route, id, item).then( response => Promise.resolve(response), error => Promise.reject(error) );
  };
  patchItem(id,item) {
    return patchItem(this.route, id, item).then( response => Promise.resolve(response), error => Promise.reject(error) );
  }
  deleteItem(id) {
    return deleteItem(this.route, id).then( response => Promise.resolve(response), error => Promise.reject(error) );
  }
  postImage(item) {
    return postFile(this.imageRoute, item).then( response => Promise.resolve(response), error => Promise.reject(error) );
  }
  postFile(item) {
    return postFile(this.fileRoute, item).then( response => Promise.resolve(response), error => Promise.reject(error) );
  }
  getMembers(id, sortBy, sortDesc, page, perPage, filter) {
    return getItems(this.route+'/'+id+'/members', sortBy, sortDesc, page, perPage, filter).then( response => Promise.resolve(response), error => Promise.reject(error) );
  };
  getModerated(page, perPage) {  
    return getItems(this.route+'/moderated', null, null, page, perPage).then( response => Promise.resolve(response), error => Promise.reject(error) );
  };
}

export default new CommunityService();