// Message display service
// note : messages received must be sanitized (remove all non alphanumerical chars), 
// otherwise the translator service can fail => that's why we use .replace(/\W/g, '')

import { store } from '../store';

class MessageService {
  success(message,context='request') {
    store.dispatch('message',{
      text: message.replace(/\W/g, ''),
      success: true,
      context: context,
      timeout: 5000,
      color: 'green'
    });
  };
  error(error,context='request') {
    store.dispatch('message',{
      text: error.replace(/\W/g, ''),
      success: false,
      context: context,
      timeout: 8000,
      color: 'red'
    });
  };
}

export default new MessageService();