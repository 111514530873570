let solidaryAnalyticRoutes = [
  {
    path: '/solidary/analytics/carpool',
    name: 'solidary_carpool_analytics',
    meta: {
      permission: 'dashboard_read',
      requiresAuth: true,
      parent: 'solidary',
      menu: 2,
      icon: 'mdi-chart-line',
      sub: true,
    },
    component: () =>
      import(/* webpackChunkName: "analytics" */ '../views/Analytics.vue')
  }
];

export { solidaryAnalyticRoutes };