'use strict';

import Vue from 'vue';
import Vuex from 'vuex';
import { auth } from './auth.module';
import { userModule } from './user.module';
import { hitchHikerModule } from './hitchHiker.module';
import { campaignModule } from './campaign.module';
import { eventModule } from './event.module';
import { articleModule } from './article.module';
import { analyticModule } from './analytic.module';
import { editorialModule } from './editorial.module';
import { communityModule } from './community.module';
import { communityMemberModule } from './communityMember.module';
import { relayPointModule } from './relayPoint.module';
import { relayPointTypeModule } from './relayPointType.module';
import { territoryModule } from './territory.module';
import { geosearchModule } from './geosearch.module';
import { iconModule } from './icon.module';
import { identityProofModule } from './identityProof.module';
import { roleModule } from './role.module';
import { scammerModule } from './scammer.module'; 

// solidary modules
import { solidaryRecordModule } from './solidaryRecord.module';
import { solidaryStructureModule } from './solidaryStructure.module';
import { solidaryBeneficiaryModule } from './solidaryBeneficiary.module';
import { solidaryVolunteerModule } from './solidaryVolunteer.module';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    auth,
    rp:relayPointModule,
    rpt:relayPointTypeModule,
    u:userModule,
    h:hitchHikerModule,
    cp:campaignModule,
    e:eventModule,
    a:articleModule,
    an:analyticModule,
    ed:editorialModule,
    t:territoryModule,
    c:communityModule,
    cm:communityMemberModule,
    g:geosearchModule,
    ro:roleModule,
    i:iconModule,
    ip:identityProofModule,
    sld_r:solidaryRecordModule,
    sld_s:solidaryStructureModule,
    sld_b:solidaryBeneficiaryModule,
    sld_v:solidaryVolunteerModule,
    sc:scammerModule
  },
  state: {
    layout: 'dashboard-layout',
    breadcrumb: [],
    alert: false,
    message: {}
  },
  actions: {
    checkLogged({commit}) {
      if (auth.state.loggedIn) {
        commit('SET_LAYOUT','dashboard-layout');
      } else {
        commit('SET_LAYOUT','login-layout');
      }
    },
    message({commit}, message) {      
      commit('SET_MESSAGE', { message: message, alert: true });
    },
    dismissMessage({commit}) {
      commit('SET_MESSAGE', { message: {}, alert: false });
    },
  },
  mutations: {
    SET_LAYOUT (state, layout) {
      state.layout = layout;
    },
    SET_BREADCRUMB (state, breadcrumb) {
      state.breadcrumb = breadcrumb;
    },
    SET_MESSAGE (state, message) {
      state.message = message.message;
      state.alert = message.alert;
    },
    CLEAR_ALERT (state) {
      state.alert = false;
    }
  },
  getters: {
    layout (state) {
      return state.layout;
    },
    breadcrumb (state) {
      return state.breadcrumb;
    },
    message (state) {
      return state.message;
    },
    alert(state) {
      return state.alert;
    },
    loggedIn () {
      return auth.state.loggedIn;
    }
  }
});