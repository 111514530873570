import getEnv from '@/utils/env';

var PageTitleMixin = {
  title() {
    const title = this.$t('routes.'+this.$route.name+'.title');
    if (title === 'routes.'+this.$route.name+'.title') {
      // no translation available, we return the default title
      return getEnv('VUE_APP_DEFAULT_TITLE');
    }
    return title;
  } 
};

export default PageTitleMixin;