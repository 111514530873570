import Login from '../views/Login.vue';
import Home from '../views/Home.vue';

let defaultRoutes = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      requiresAuth: true,
      menu: 1,
      icon: 'mdi-home'
    }
  },
];

export { defaultRoutes };