let userRoutes = [
  {
    path: '/users',
    name: 'users',
    meta: {
      permission: 'user_list',
      requiresAuth: true,
      parent: 'home',
      show: 'user_show',
      edit: 'user_edit',
      create: 'user_create',
      importUsers: 'users_import',
      menu: 3,
      icon: 'mdi-account',
    },
    component: () =>
      import(/* webpackChunkName: "users" */ '../views/Users.vue')
  },
  {
    path: '/users/create',
    name: 'user_create',
    meta: {
      permission: 'user_create',
      requiresAuth: true,
      parent: 'users',
      icon: 'mdi-account'
    },
    props: {
      edit: false
    },
    component: () =>
      import(/* webpackChunkName: "users" */ '../views/Users.vue')
  },
  {
    path: '/users/import',
    name: 'users_import',
    meta: {
      permission: 'user_create',
      requiresAuth: true,
      parent: 'users',
      icon: 'mdi-alpha-p-circle-outline'
    },
    props: {
      edit: false
    },
    component: () =>
      import(/* webpackChunkName: "relaypoints" */ '../views/Users.vue')
  },
  {
    path: '/users/show/:id',
    name: 'user_show',
    meta: {
      permission: 'user_read',
      requiresAuth: true,
      parent: 'users',
      edit: 'user_edit',
      icon: 'mdi-account'
    },
    component: () =>
      import(/* webpackChunkName: "users" */ '../views/Users.vue')
  },
  {
    path: '/users/:id',
    name: 'user_edit',
    meta: {
      permission: 'user_update',
      requiresAuth: true,
      parent: 'users',
      icon: 'mdi-account'
    },
    props: {
      edit: true
    },
    component: () =>
      import(/* webpackChunkName: "users" */ '../views/Users.vue')
  },
];

export { userRoutes };