import RequestService from './request.service';

class PermissionService {
  getPermissions() {
    return RequestService
      .get('permissions')
      .then(response => Promise.resolve(response.data) )
      .catch( error => Promise.reject(error) );
  };
}

export default new PermissionService();