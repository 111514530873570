import Vue from 'vue';
import VueRouter from 'vue-router';
import getEnv from '@/utils/env';

import { store } from '../store/index';
import { analyticRoutes } from './analytic';
import { userRoutes } from './user';
import { hitchHikerRoutes } from './hitchHiker';
import { campaignRoutes } from './campaign';
import { communityRoutes } from './community';
import { eventRoutes } from './event';
import { relayPointRoutes } from './relayPoint';
import { articleRoutes } from './article';
import { editorialRoutes } from './editorial';
import { solidaryRoutes } from './solidary';
import { settingsRoutes } from './settings';
import { defaultRoutes } from './default';

Vue.use(VueRouter);

// note : use common paths for parent/child routes (eg: /users for list, /users/create for creation, /users/edit for edition...) 
// this way, the navigation drawer will highlight the current group even for child routes
const routes = [
  ...defaultRoutes,
  ...(getEnv('VUE_APP_ANALYTIC') === 'true' ? analyticRoutes : []),
  ...userRoutes,
  ...(getEnv('VUE_APP_HITCH_HIKING') === 'true' ? hitchHikerRoutes : []),
  ...(getEnv('VUE_APP_CAMPAIGN') === 'true' ? campaignRoutes : []),
  ...(getEnv('VUE_APP_COMMUNITY') === 'true' ? communityRoutes : []),
  ...(getEnv('VUE_APP_EVENT') === 'true' ? eventRoutes : []),
  ...articleRoutes,
  ...(getEnv('VUE_APP_EDITORIAL') === 'true' ? editorialRoutes : []),
  ...relayPointRoutes,
  ...(getEnv('VUE_APP_SOLIDARY') === 'true' ? solidaryRoutes : []),
  ...settingsRoutes
];

const router = new VueRouter({
  mode: 'history',
  base: getEnv('BASE_URL'),
  routes
});

router.beforeEach((to, from, next) => {
  // check auth required
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.auth.loggedIn) {
      next({ name: 'login' });
    }
  }
  // by default, all actions are not solidary editions (needed to freeze the solidary structure)
  // it will be checked later if the current action is in fact a solidary edition
  if (store.state.auth.loggedIn) {
    store.dispatch('auth/setSolidaryEdit',false);
  }
  // check permission
  if (to.matched.some(record => record.meta.permission)) {
    // permission needed
    if (store.state.auth.permissions.filter(function(item){
      if (item == to.meta.permission) {
        return true;
      }
      return false;
    }).length>0) {
      // permission granted
      next();
    } else {
      // no permission
      return;
    }
  }
  // no auth nor permission required
  next();
});

export default router;