import { getItems, getItem, postItem, patchItem, getSpecialItem } from './common.service';
import RequestService from './request.service';

class HitchHikerService {
  route = 'admin/users';
  imageRoute = 'admin/images';
  getItems(sortBy, sortDesc, page, perPage, filter) {
    filter['isHitchHiker'] = true;
    return getItems(this.route, sortBy, sortDesc, page, perPage, filter).then( response => Promise.resolve(response), error => Promise.reject(error) );
  };
  getItem(id) {
    return getItem(this.route,id, 'json-normalized')
      .then(response => {
        // key is needed for geocomplete component
        if (response.homeAddress) {
          response.homeAddress.key = 0;
        }
        if (response.hitchHikePassenger === undefined) {
          response.hitchHikePassenger = false;
        }
        if (response.hitchHikeDriver === undefined) {
          response.hitchHikeDriver = false;
        }
        // we add a newImage field to track image change
        return Promise.resolve({
          ...response,
          newImage: false
        });
      })
      .catch( error => Promise.reject(error) );
  };
  getTerritoryStatus(id) {
    return getSpecialItem(this.route, id, 'rzpTerritoryStatus')
      .then(response => {
        // we add a newImage field to track image change
        return Promise.resolve({
          ...response,
        });
      })
      .catch( error => Promise.reject(error) );
  };  postItem(item) {
    // phoneDisplay is mandatory
    item.phoneDisplay = 1;
    return postItem(this.route, item)
      .then(response => {
        // key is needed for geocomplete component
        if (response.homeAddress !== undefined) {
          response.homeAddress.key = 0;
        }
        return Promise.resolve(response);
      })
      .catch( error => Promise.reject(error) );
  };
  patchItem(id,item) {
    return patchItem(this.route, id, item)
      .then(response => {
        // key is needed for geocomplete component
        if (response.homeAddress !== undefined) {
          response.homeAddress.key = 0;
        }
        return Promise.resolve(response);
      })
      .catch( error => Promise.reject(error) );
  };
  exportAll() {
    return RequestService.get(`${this.route}/export`, {isHitchHiker: true}, 'csv')
      .then((r) => Promise.resolve(r), error => Promise.reject(error));
  };
  exportFiltered(filter={}){
    return RequestService.get(`${this.route}/export`, filter, 'csv')
      .then((r) => Promise.resolve(r), error => Promise.reject(error));
  };
  exportSelected(selection=[]){
    let filter = {
      selected: selection.join(',')
    }
    return RequestService.get(`${this.route}/export`, filter, 'csv')
      .then((r) => Promise.resolve(r), error => Promise.reject(error));
  };  
}

export default new HitchHikerService();