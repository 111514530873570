import { patchItem } from './common.service';
import { postItem } from './common.service';

class IdentityProofService {
  route = 'admin/identity_proofs';
  patchItem(id,item) {
    return patchItem(this.route, id, item)
      .then(response => {
        return Promise.resolve(response);
      })
      .catch( error => Promise.reject(error) );
  };
  postItem(item) {
    return postItem(this.route, item).then( response => Promise.resolve(response), error => Promise.reject(error) );
  };
}

export default new IdentityProofService();