import CampaignService from '../services/campaign.service';
import MessageService from '../services/message.service';
import UserService from '../services/user.service';
import CommunityMemberService from '../services/communityMember.service';
import { initialState, defaultActions, defaultMutations, defaultGetters } from './common.module';
import { clone } from 'lodash';

export const campaignModule = {
  namespaced:true,
  state: {
    ...initialState(),
    // source for deliveries
    deliverySource: null,
    // source id for deliveries
    deliverySourceId: null,
    // source name for deliveries
    deliverySourceName: null,
    // filter type for deliveries
    deliveryFilterType: null,
    // filters for deliveries
    deliveryFilters: [],
    // list of deliveries (if filter = selection)
    deliveries: [],
    // total number of deliveries
    totalDeliveries: 0
  },
  mutations: {
    ...defaultMutations(),
    SAVE_DELIVERY_SOURCE(state, source) {
      state.deliverySource = source;
    },
    SAVE_DELIVERY_SOURCE_ID(state, id) {
      state.deliverySourceId = id;
    },
    SAVE_DELIVERY_SOURCE_NAME(state, name) {
      state.deliverySourceName = name;
    },
    SAVE_DELIVERY_FILTER_TYPE(state, type) {
      state.deliveryFilterType = type;
    },
    SAVE_DELIVERY_FILTERS(state, filters) {
      state.deliveryFilters = filters;
    },
    SAVE_DELIVERIES(state, deliveries) {
      state.deliveries = deliveries;
      state.totalDeliveries = deliveries.length;
    },
    SAVE_TOTAL_DELIVERIES(state, total) {
      state.totalDeliveries = total;
    }
  },
  getters: {
    ...defaultGetters(),
    deliverySource (state) {
      return state.deliverySource;
    },
    deliverySourceId (state) {
      return state.deliverySourceId;
    },
    deliverySourceName (state) {
      return state.deliverySourceName;
    },
    deliveryFilterType (state) {
      return state.deliveryFilterType;
    },
    deliveryFilters (state) {
      return state.deliveryFilters;
    },
    deliveries (state) {
      return state.deliveries;
    },
    totalDeliveries (state) {
      return state.totalDeliveries;
    }
  },
  actions: {
    ...defaultActions(),
    loadItems({commit, state}, { callOptions, callFilter } = {} ) {
      // override is used when the load doesn't concern the 'main' resource management, eg. a filter, a related selection... 
      // then we don't want to store the options or filter for further use
      let override = false;
      let options = {...state.options}; // { ... } ensure a real copy, not a reference
      let filter = {...state.filter};
      if (callOptions != undefined) {
        if (callOptions.sortBy != undefined) options.sortBy = callOptions.sortBy;
        if (callOptions.sortDesc != undefined) options.sortDesc = callOptions.sortDesc;
        if (callOptions.page != undefined) options.page = callOptions.page;
        if (callOptions.itemsPerPage != undefined) options.itemsPerPage = callOptions.itemsPerPage;
        override = true;
      }
      if (callFilter != undefined) {
        filter = callFilter;
        override = true;
      } 
      commit('SAVE_LOADING', true);
      CampaignService.getItems(options.sortBy, options.sortDesc, options.page, options.itemsPerPage, filter).then(response => {
        if (response.total == 0) {
          // no page to display
          return;
        }
        if (!override) {
          // check if current options are still valid : last load may have changed the number of items, so current page may not be valid for example
          let nbpages = Math.ceil(response.total/options.itemsPerPage);
          if (nbpages<options.page) {
            let newOptions = { ...options };
            if (nbpages>0) {
              newOptions.page = nbpages;
            } else {
              newOptions.page = 1;
            }
            commit('SAVE_OPTIONS',newOptions);
          }
        }
        commit('SAVE_ITEMS', response);
      }).catch( () => {
        commit('SAVE_ERROR', true);
      }).finally( () => {
        commit('SAVE_LOADING', false);
      });
    },
    loadItem({commit, state}, { id, fields } ) {
      // clear item if given id is different than current id
      if (state.item && state.item.id && id != state.item.id) {
        commit('SAVE_ITEM', null);
      }
      commit('SAVE_LOADING', true);
      // replace here by the real service
      CampaignService.getItem(id).then(response => {
        // transform body from json string to array
        if (response.body) {
          response.body = JSON.parse(response.body);
          // reorder by position
          response.body.sort((a,b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0));
        }
        // complete the item with potential nullable fields that were not retrieved
        fields.forEach( item => {
          if (response[item] === undefined) {
            response[item] = null;
          }
        });
        commit('SAVE_ITEM', response);
      }).catch( () => {
        MessageService.error('loaded','campaign');
        commit('SAVE_ERROR', true);
      }).finally( () => {
        commit('SAVE_LOADING', false);
      });
    },
    postItem( { commit }, item) {
      // post for campaign are a bit tricky :
      // - we post the campaign without images, the body could contain references to images though
      // - if there are images, we post them and update the body with the images id at the right place
      // - then we patch the campaign with the updated body

      // we clone the body to keep it for further use
      let body = clone(item.body);
      // json encode body to send initial campaign, without images
      item.body = JSON.stringify(item.body);
      commit('SAVE_LOADING', true);
      CampaignService.postItem(item).then( async response => {
        // initial campaign is sent, we check for images
        let hasImages = false;
        // put back the original body as array to check each element
        item.body = body;
        let i = 0;
        // array that will contain the updated body
        let elements = [];
        // promises array, to be sure that all images are posted successfully, as well as associate if it is needed
        var promises = [];
        // check each body element for images
        item.body.forEach( element => {
          if (element.type == 'image' && element.preview && element.src) {
            // the element is an image, we upload it
            i++;
            hasImages = true;
            let formData = new FormData();
            formData.append('campaignId', response.id);
            formData.append('campaignFile', element.src);
            formData.append('position', i);
            promises.push(
              CampaignService.postImage(formData).then( image => {
                elements.push({
                  type: 'image',
                  id: image.id,
                  src: image.avatar,
                  position: element.position
                });
              }).catch( () => {
                return Promise.reject();
              })
            );
          } else {
            // not an image, we add the element as-is
            elements.push(element);
          }
        });
        if (item.source == 1) {
          // associate users
          promises.push(
            UserService.associate(response.id,item.filterType,item.filter,item.deliveries).then( () => {
              return Promise.resolve();
            }).catch( () => {
              return Promise.reject();
            })
          );
        } else if (item.source == 2) {
          // associate community members
          promises.push(
            CommunityMemberService.associate(response.id,item.filterType,item.filter,item.deliveries,item.sourceId).then( () => {
              return Promise.resolve();
            }).catch( () => {
              return Promise.reject();
            })
          );
        }
        await Promise.all(promises);
        // all is ok, we return the promise to continue the process
        return await Promise.resolve({
          response,
          elements,
          hasImages
        });
      }).then( ({response, elements, hasImages}) => {
        if (hasImages) {
          // images has been posted, we patch the campaign
          let newBody = JSON.stringify(elements);
          CampaignService.patchItem(response.id, { body: newBody }).then(response => {
            // the body is treated as an array in the components
            response.body = elements;
            commit('SAVE_ITEM', response);
            MessageService.success('created','campaign');
          }).catch( () => {
            return Promise.reject();
          });
        } else {
          // the body is treated as an array in the components
          response.body = elements;
          commit('SAVE_ITEM', response);
          MessageService.success('created','campaign');
        }
      }).catch( () => {
        MessageService.error('created','campaign');
        commit('SAVE_ERROR', true);
      }).finally( () => {
        commit('SAVE_LOADING', false);
      });
    },
    patchItem({commit}, data) {
      // patch for campaign are a bit tricky :
      // - we patch the campaign without images, the body could contain references to images though
      // - if there are new images or updated images, we post them and update the body with the images id at the right place
      // - then we patch the campaign with the updated body

      const { id, item } = data;

      // we clone the body to keep it for further use
      let body = item.body ? clone(item.body) : null;
  
      // json encode body to send initial campaign, without images
      if (item.body) {
        item.body = JSON.stringify(item.body);
      }
  
      commit('SAVE_LOADING', true);
      CampaignService.patchItem(id, item).then( async response => {
        // initial campaign is sent, we check for images
        let hasImages = false;
        // put back the original body as array to check each element
        item.body = body;
        // array that will contain the updated body
        let elements = [];
        // promises array, to be sure that all images are posted successfully
        var promises = [];
        // check each body element for images (warning, body may be null if the patch concerns the title or subject only)
        if (body) {
          item.body.forEach( element => {
            if (element.type == 'image' && element.preview && element.newSrc) {
              // the element is an updated image, we upload it
              hasImages = true;
              let formData = new FormData();
              formData.append('campaignId', response.id);
              formData.append('campaignFile', element.newSrc);
              promises.push(
                CampaignService.postImage(formData).then( image => {
                  elements.push({
                    type: 'image',
                    id: image.id,
                    src: image.avatar,
                    position: element.position
                  });
                }).catch( () => {
                  return Promise.reject();
                })
              );
            } else if (element.type == 'image' && element.preview && element.src && element.src.name) {
              // the element is a new image, we upload it (we know it's a new image because the src has a name, if it's an existing image the src is a simple string)
              hasImages = true;
              let formData = new FormData();
              formData.append('campaignId', response.id);
              formData.append('campaignFile', element.src);
              promises.push(
                CampaignService.postImage(formData).then( image => {
                  elements.push({
                    type: 'image',
                    id: image.id,
                    src: image.avatar,
                    position: element.position
                  });
                }).catch( () => {
                  return Promise.reject();
                })
              );
            } else {
              // not an image, nor an updated image, we add the element as-is
              elements.push(element);
            }
          });
        } else {
          // the body is retrieved from the response
          response.body = JSON.parse(response.body);
          // reorder by position
          response.body.sort((a,b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0));
          elements = response.body;
        }
        await Promise.all(promises);
        // all is ok, we return the promise to continue the process
        return await Promise.resolve({
          response,
          elements,
          hasImages
        });
      }).then( ({response, elements, hasImages}) => {
        if (hasImages) {
          // images has been posted, we patch the campaign
          let newBody = JSON.stringify(elements);
          CampaignService.patchItem(response.id, { body: newBody }).then(response => {
            // the body is treated as an array in the components
            response.body = elements;
            commit('SAVE_ITEM', response);
            MessageService.success('updated','campaign');
          }).catch( () => {
            return Promise.reject();
          });
        } else {
          // the body is treated as an array in the components
          response.body = elements;
          commit('SAVE_ITEM', response);
          MessageService.success('updated','campaign');
        }
      }).catch( () => {
        MessageService.error('updated','campaign');
        commit('SAVE_ERROR', true);
      }).finally( () => {
        commit('SAVE_LOADING', false);
      });
    },
    deleteItem({commit}, id) {
      commit('SAVE_LOADING', true);
      CampaignService.deleteItem(id).then( () => {
        MessageService.success('deleted','campaign');
        commit('SAVE_ITEM', null);
      }).catch( () => {
        MessageService.error('deleted','campaign');
        commit('SAVE_ERROR', true);
      }).finally( () => {
        commit('SAVE_LOADING', false);
      });
    },
    saveDeliverySource({commit}, source) {
      commit('SAVE_DELIVERY_SOURCE', source);
    },
    saveDeliverySourceId({commit}, sourceId) {
      commit('SAVE_DELIVERY_SOURCE_ID', sourceId);
    },
    saveDeliverySourceName({commit}, sourceName) {
      commit('SAVE_DELIVERY_SOURCE_NAME', sourceName);
    },
    saveDeliveryFilterType({commit}, type) {
      commit('SAVE_DELIVERY_FILTER_TYPE', type);
    },
    saveDeliveryFilters({commit}, filters) {
      commit('SAVE_DELIVERY_FILTERS_TYPE', filters);
    },
    saveDeliveries({commit}, { deliveries, source, filterType, sourceId = null, sourceName = null, count = 0, filters = [] }) {
      commit('SAVE_DELIVERIES', deliveries);
      commit('SAVE_DELIVERY_SOURCE', source);
      commit('SAVE_DELIVERY_SOURCE_ID', sourceId);
      commit('SAVE_DELIVERY_SOURCE_NAME', sourceName);
      commit('SAVE_DELIVERY_FILTER_TYPE', filterType);
      if (filterType == 2) {
        commit('SAVE_TOTAL_DELIVERIES', count);
        commit('SAVE_DELIVERY_FILTERS', filters);
      }
    },
    saveTotalDeliveries({commit}, total) {
      commit('SAVE_TOTAL_DELIVERIES', total);
    },
    clearDeliveries({commit}) {
      commit('SAVE_DELIVERIES', []);
      commit('SAVE_DELIVERY_SOURCE', null);
      commit('SAVE_DELIVERY_SOURCE_ID', null);
      commit('SAVE_DELIVERY_SOURCE_NAME', null);
      commit('SAVE_DELIVERY_FILTER_TYPE', null);
      commit('SAVE_DELIVERY_FILTERS', []);
    },
  }
};
