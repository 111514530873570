// uncomment if needed
// import RequestService from './request.service';
import { getItems, getItem, postItem, putItem, patchItem, deleteItem } from './common.service';

class ArticleService {
  route = 'admin/articles';
  getItems(sortBy, sortDesc, page, perPage, filter) {
    return getItems(this.route, sortBy, sortDesc, page, perPage, filter).then( response => Promise.resolve(response), error => Promise.reject(error) );
  }
  getItem(id) {
    return getItem(this.route,id).then( response => Promise.resolve(response), error => Promise.resolve(error) );
  }
  postItem(item) {
    return postItem(this.route, item).then( response => Promise.resolve(response), error => Promise.reject(error) );
  }
  putItem(id,item) {
    return putItem(this.route, id, item).then( response => Promise.resolve(response), error => Promise.reject(error) );
  }
  patchItem(id,item) {
    return patchItem(this.route, id, item).then( response => Promise.resolve(response), error => Promise.reject(error) );
  }
  deleteItem(id) {
    return deleteItem(this.route, id).then( response => Promise.resolve(response), error => Promise.reject(error) );
  }
}

export default new ArticleService();