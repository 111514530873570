<template>
  <v-app>
    <component :is="layout" />
  </v-app>
</template>

<script>
import LoginLayout from './layouts/LoginLayout';
import DashboardLayout from './layouts/DashboardLayout';

export default {
  components: {
    'login-layout': LoginLayout,
    'dashboard-layout': DashboardLayout,
  },
  computed: {
    layout() {
      this.checkLogged();
      return this.$store.getters.layout;
    },
  },
  methods: {
    checkLogged() {
      this.$store.dispatch('checkLogged');
    } 
  }
};
</script>