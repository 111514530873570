// uncomment if needed
// import RequestService from './request.service';
import { getItems, getItem, patchItem, deleteItem } from './common.service';

class SolidaryBeneficiaryService {
  route = 'admin/solidary_beneficiaries';
  getItems(sortBy, sortDesc, page, perPage, filter) {
    return getItems(this.route, sortBy, sortDesc, page, perPage, filter).then( response => Promise.resolve(response), error => Promise.reject(error) );
  };
  getItem(id) {
    return getItem(this.route,id).then( response => Promise.resolve(response), error => Promise.reject(error) );
  };
  patchItem(id,item) {
    // remove unwanted properties : diaries, proofs, structures
    delete item.diaries;
    delete item.proofs;
    delete item.structures;
    return patchItem(this.route, id, item).then( response => Promise.resolve(response), error => Promise.reject(error) );
  }
  deleteItem(id) {
    return deleteItem(this.route, id).then( response => Promise.resolve(response), error => Promise.reject(error) );
  }
}

export default new SolidaryBeneficiaryService();