// uncomment if needed
import RequestService from './request.service';
class PlatformService {

    getEECAvailable() {
        return RequestService.get("eec/instance").then(response => {
            if(response.data && response.data.available){
                return response.data;                
            }
            return false;
         }).catch( (e) => {
           console.log(e);
           return false;
         });        
    };
}
export default new PlatformService();