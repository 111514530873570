import CommunityService from '../services/community.service';
import MessageService from '../services/message.service';
import userService from '../services/user.service';
import CsvExport from '../services/csvExport';
import ImportService from '../services/import.service';
import { initialState, defaultActions, defaultMutations, defaultGetters } from './common.module';


export const communityModule = {
  namespaced:true,
  state: initialState(),  // mix with custom state if needed
  mutations: {
    ...defaultMutations()
    // add custom mutations
  },
  getters: {
    ...defaultGetters()
    // add custom getters
  },
  actions: {
    ...defaultActions(),
    loadItems({commit, state}, primary=true) {
      commit('SAVE_LOADING', true);
      // replace here by the real service
      CommunityService.getItems(state.options.sortBy, state.options.sortDesc, state.options.page, state.options.itemsPerPage, state.filter).then(response => {
        if (primary) {
          // this load concerns the main component => it's not a load to populate a subcomponent, we need to check the options
          // check if current options are still valid : last load may have changed the number of items, so current page may not be valid for example
          let nbpages = Math.ceil(response.total/state.options.itemsPerPage);
          if (nbpages<state.options.page) {
            let newOptions = state.options;
            if (nbpages>0) {
              newOptions.page = nbpages;
            } else {
              newOptions.page = 1;
            }
            commit('SAVE_OPTIONS',newOptions);
          }
        }
        commit('SAVE_ITEMS', response);
      }).catch( () => {
        commit('SAVE_ERROR', true);
      }).finally( () => {
        commit('SAVE_LOADING', false);
      });
    },
    loadItem({commit, state}, { id, fields} ) {
      // clear item if given id is different than current id
      if (state.item && state.item.id && id != state.item.id) {
        commit('SAVE_ITEM', null);
      }
      commit('SAVE_LOADING', true);
      // replace here by the real service
      CommunityService.getItem(id).then(response => {
        // complete the item with potential nullable fields that were not retrieved
        fields.forEach( item => {
          if (response[item] === undefined) {
            response[item] = null;
          }
        });
        // add required fields for image and file management
        response.newImage = null;
        response.newFile = null;
        response.resetImage = false;
        commit('SAVE_ITEM', response);
      }).catch( () => {
        MessageService.error('loaded','community');
        commit('SAVE_ERROR', true);
      }).finally( () => {
        commit('SAVE_LOADING', false);
      });
    },
    postItem({commit}, item) {
      commit('SAVE_LOADING', true);

      if (item.target) {
        item.target = parseInt(item.target)
      }
      
      CommunityService.postItem(item).then(async response => {

        var promises = [];
        var completedResponse = {...response};

        if(item.newImage){

          let formData = new FormData();
          formData.append('communityId', response.id);
          formData.append('communityFile', item.newImage);
          formData.append('position', 1);

          promises.push(
            CommunityService.postImage(formData).then( image => {
              completedResponse['newImage'] = null;
              completedResponse['resetImage'] =  false, // no need to reset the image field, we will redirect to the community list
              completedResponse['image'] = image.image;
              completedResponse['avatar'] = image.avatar;
            }).catch( () => {
              return Promise.reject('community');
            })
          );
        }


        if(item.newFile){

          let formData = new FormData();
          formData.append('communityId', response.id);
          formData.append('file', item.newFile);

          promises.push(
            CommunityService.postFile(formData).then( () => {
              completedResponse['newFile'] = null;
            }).catch( () => {
              return Promise.reject('community_securities');
            })
          );
        }

        await Promise.all(promises);
        return await Promise.resolve(completedResponse);
        
      }).then( response => {
        commit('SAVE_ITEM', response);
        MessageService.success('created','community');
      }).catch( (error) => {
        MessageService.error('created',error);
        commit('SAVE_ERROR', true);
      }).finally( () => {
        commit('SAVE_LOADING', false);
      });
    },
    patchItem({commit}, data) {
      const { id, item } = data;

      if (item.target) {
        item.target = parseInt(item.target)
      }

      commit('SAVE_LOADING', true);
      CommunityService.patchItem(id, item).then(async response => {

        var promises = [];
        var completedResponse = {...response};

        if(item.newImage){

          let formData = new FormData();
          formData.append('communityId', response.id);
          formData.append('communityFile', item.newImage);
          formData.append('position', 1);

          promises.push(
            CommunityService.postImage(formData).then( image => {
              completedResponse['newImage'] = null;
              completedResponse['resetImage'] =  false, // no need to reset the image field, we will redirect to the community list
              completedResponse['image'] = image.image;
              completedResponse['avatar'] = image.avatar;
            }).catch( () => {
              return Promise.reject('community');
            })
          );
        }


        if(item.newFile){

          let formData = new FormData();
          formData.append('communityId', response.id);
          formData.append('file', item.newFile);

          promises.push(
            CommunityService.postFile(formData).then( () => {
              completedResponse['newFile'] = null;
            }).catch( () => {
              return Promise.reject('community_securities');
            })
          );
        }

        await Promise.all(promises);
        return await Promise.resolve(completedResponse);

      }).then( response => {
        commit('SAVE_ITEM', response);
        MessageService.success('updated','community');
      }).catch( (error) => {
        MessageService.error('updated',error);
        commit('SAVE_ERROR', true);
      }).finally( () => {
        commit('SAVE_LOADING', false);
      });
    },
    deleteItem({commit}, id) {
      commit('SAVE_LOADING', true);
      CommunityService.deleteItem(id).then( () => {
        MessageService.success('deleted','community');
        commit('SAVE_ITEM', null);
      }).catch( () => {
        MessageService.error('deleted','community');
        commit('SAVE_ERROR', true);
      }).finally( () => {
        commit('SAVE_LOADING', false);
      });
    },
    exportAll({}, communityId) {
      userService.exportAll({community: communityId}).then((r) => {
        if (r.data) {
          CsvExport.exportToCsv('users', r.data);
        }
      })
    },
    importCollection({commit}, data){
      if(data instanceof File){
        let formData = new FormData();
        formData.append('file', data);
        formData.append('filename', data.name);

        commit('IMPORT_LOADING', true);
        ImportService.postFile(formData, 'users').then(response => {
          commit('IMPORT_RESPONSE', response);  
          commit('IMPORT_LOADING', false);
          }
        );
      }
    }
  }
};
