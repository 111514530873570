let analyticRoutes = [
  {
    path: '/analytics',
    name: 'analytics_admin',
    meta: {
      permission: 'analytic_list',
      requiresAuth: true,
      parent: 'home',
      menu: 2,
      icon: 'mdi-chart-line'
    },
    component: () =>
      import(/* webpackChunkName: "analytics" */ '../views/Analytics.vue')
  }
];

export { analyticRoutes };