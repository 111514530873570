import { relayPointTypeRoutes } from './relayPointType';

let settingsRoutes = [
  {
    path: '/settings',
    skip: true,
    name: 'settings',
    meta: {
      parent: 'home',
      menu: 1,
      icon: 'mdi-cog',
      subs: 'settings'
    },
  },
  ...relayPointTypeRoutes
];

export { settingsRoutes };