import HitchHikerService from '../services/hitchHiker.service';
import MessageService from '../services/message.service';
import { initialState, defaultActions, defaultMutations, defaultGetters } from './common.module';
import CsvExport from '../services/csvExport';

export const hitchHikerModule = {
  namespaced:true,
  state: {
    ...initialState(),
    associated: null,
    sentTest: null,
    sentProd: null
  },
  mutations: {
    ...defaultMutations(),
    // add custom mutations
    SAVE_ASSOCIATED(state, val) {
      state.associated = val;
    },
    SAVE_SENTTEST(state, val) {
      state.sentTest = val;
    },
    SAVE_SENTPROD(state, val) {
      state.sentProd = val;
    }
  },
  getters: {
    ...defaultGetters(),
    associated (state) {
      return state.associated;
    },
    sentTest (state) {
      return state.sentTest;
    },
    sentProd (state) {
      return state.sentProd;
    }
  },
  actions: {
    ...defaultActions(),
    loadItems({commit, state}) {
      commit('SAVE_LOADING', true);
      // replace here by the real service
      HitchHikerService.getItems(state.options.sortBy, state.options.sortDesc, state.options.page, state.options.itemsPerPage, state.filter).then(response => {
        let nbpages = Math.ceil(response.total/state.options.itemsPerPage);
        if (nbpages<state.options.page) {
          let newOptions = state.options;
          if (nbpages>0) {
            newOptions.page = nbpages;
          } else {
            newOptions.page = 1;
          }
          commit('SAVE_OPTIONS',newOptions);
        }
        commit('SAVE_ITEMS', response);
      }).catch( () => {
        commit('SAVE_ERROR', true);
      }).finally( () => {
        commit('SAVE_LOADING', false);
      });
    },
    loadItem({commit, state}, { id, fields} ) {
      // clear item if given id is different than current id
      if (state.item && state.item.id && id != state.item.id) {
        commit('SAVE_ITEM', null);
      }
      commit('SAVE_LOADING', true);
      // replace here by the real service
      HitchHikerService.getItem(id).then(response => {
        // complete the item with potential nullable fields that were not retrieved
        fields.forEach( item => {
          if (response[item] === undefined) {
            response[item] = null;
          }
        });
        // add required fields for image management
        response.newImage = null;
        response.resetImage = false;
        commit('SAVE_ITEM', response);

        HitchHikerService.getTerritoryStatus(id).then(response => {
          state.item.territoryStatus = response.rzpTerritoryStatus;
          commit('SAVE_ITEM', state.item);
        });

      }).catch( error => {
        MessageService.error('loaded','user');
        commit('SAVE_ERROR', true);
        return error;
      }).finally( () => {
        commit('SAVE_LOADING', false);
      });
    },
    getTerritoryStatus(){

    },
    postItem({commit}, item) {
      commit('SAVE_LOADING', true);
      HitchHikerService.postItem(item).then(response => {
        // check for a new image 
        if (item.newImage) {
          let formData = new FormData();
          formData.append('userId', response.id);
          formData.append('userFile', item.newImage);
          formData.append('position', 1);
          HitchHikerService.postImage(formData).then( image => {
            let completedResponse = {
              ...response,
              newImage: null,
              resetImage: false, // no need to reset the image field, we will redirect to the user list
              image: image.image,
              avatar: image.avatar
            };
            commit('SAVE_ITEM', completedResponse);
            MessageService.success('created','user');
          }).catch( () => {
            MessageService.error('created','user');
            commit('SAVE_ERROR', true);
          });
        } else {
          commit('SAVE_ITEM', response);
          MessageService.success('created','user');
        }
      }).catch( () => {
        MessageService.error('created','user');
        commit('SAVE_ERROR', true);
      }).finally( () => {
        commit('SAVE_LOADING', false);
      });
    },
    patchItem({commit}, data) {
      const { id, item } = data;
      commit('SAVE_LOADING', true);
      HitchHikerService.patchItem(id, item).then(response => {
        // check for a new image 
        if (item.newImage) {
          let formData = new FormData();
          formData.append('userId', id);
          formData.append('userFile', item.newImage);
          formData.append('position', 1);
          HitchHikerService.postImage(formData).then( image => {
            let completedResponse = {
              ...response,
              newImage: null,
              resetImage: true, // to clear the new image in the image field component
              image: image.image,
              avatar: image.avatar
            };
            commit('SAVE_ITEM', completedResponse);
            MessageService.success('updated','user');
          }).catch( () => {
            MessageService.error('updated','user');
            commit('SAVE_ERROR', true);
          });
        } else {
          // add required fields for image management
          response.newImage = null;
          response.resetImage = false;
          commit('SAVE_ITEM', response);
          MessageService.success('updated','user');
        }
      }).catch( () => {
        MessageService.error('updated','user');
        commit('SAVE_ERROR', true);
      }).finally( () => {
        commit('SAVE_LOADING', false);
      });
    },
    saveAssociated({commit}, val) {
      commit('SAVE_ASSOCIATED', val);
    },
    saveSentTest({commit}, val) {
      commit('SAVE_SENTTEST', val);
    },
    saveSentProd({commit}, val) {
      commit('SAVE_SENTPROD', val);
    },
    exportAll({commit}) {
      commit('SAVE_LOADING_EXPORT', true);
      HitchHikerService.exportAll().then((r) => {
        if (r.data) {
          CsvExport.exportToCsv('hitchhikers', r.data);
        }
      }).finally(() => {
        commit('SAVE_LOADING_EXPORT', false);
      })
    },
    exportSelected({commit}, selection) {
      commit('SAVE_LOADING_EXPORT', true);
      HitchHikerService.exportSelected(selection).then((r) => {
        if (r.data) {
          CsvExport.exportToCsv('hitchhikers', r.data);
        }
      }).finally(() => {
        commit('SAVE_LOADING_EXPORT', false);
      })
    },
    exportFiltered({commit}, filter) {
      commit('SAVE_LOADING_EXPORT', true);
      HitchHikerService.exportFiltered(filter).then((r) => {
        if (r.data) {
          CsvExport.exportToCsv('hitchhikers', r.data);
        }
      }).finally(() => {
        commit('SAVE_LOADING_EXPORT', false);
      })
    },
  }
};
