import RequestService from './request.service';

class GeosearchService {
  getItems(search) {
    let params = {
      search: search
    };
    return RequestService
      .get('points', params, 'hydra')
      .then(response => {
        return Promise.resolve({
          items: response.data['hydra:member'],
          total: response.data['hydra:totalItems']
        });
      })
      .catch( error => Promise.resolve(error) );
  };
}

export default new GeosearchService();