<template>
  <v-main>
    <v-container 
      fill-height
    >
      <router-view />
    </v-container>

    <!-- alert messages -->
    <v-snackbar
      v-model="alert"
      :timeout="message.timeout"
      :color="message.color"
      rounded="pill"
    >
      {{ $t(message.context+'.'+(message.success ? 'success' : 'error')+'.'+message.text) }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="alert = false"
        >
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-main>
</template>

<script>
import {messages_layouts_en, messages_layouts_fr} from '@/translations/layouts/Login/';
import {messages_messages_en, messages_messages_fr} from '@/translations/messages/';

let messages_en = { ...messages_layouts_en,  ...messages_messages_en };
let messages_fr = { ...messages_layouts_fr,  ...messages_messages_fr };

export default {
  name: 'LoginLayout',
  i18n: {
    messages: {
      'en': messages_en,
      'fr': messages_fr
    }
  },

  data: () => ({
  }),

  computed: {
    message() {
      return this.$store.state.message;
    },
    alert: {
      get: function() {
        return this.$store.state.alert;
      },
      set: function() {
        this.$store.dispatch('dismissMessage');
      }
    }
  },
};
</script>