import CommunityService from '../services/community.service';
import CommunityMemberService from '../services/communityMember.service';
import MessageService from '../services/message.service';
import { initialState, defaultActions, defaultMutations, defaultGetters } from './common.module';

export const communityMemberModule = {
  namespaced:true,
  state: {
    ...initialState(),
    // associate status
    associated: null,
    sentTest: null,
    sentProd: null
  },
  mutations: {
    ...defaultMutations(),
    SAVE_ASSOCIATED(state, val) {
      state.associated = val;
    },
    SAVE_SENTTEST(state, val) {
      state.sentTest = val;
    },
    SAVE_SENTPROD(state, val) {
      state.sentProd = val;
    }
  },
  getters: {
    ...defaultGetters(),
    associated (state) {
      return state.associated;
    },
    sentTest (state) {
      return state.sentTest;
    },
    sentProd (state) {
      return state.sentProd;
    }
  },
  actions: {
    ...defaultActions(),
    loadItems({commit, state}, id) {
      commit('SAVE_LOADING', true);
      CommunityService.getMembers(id, state.options.sortBy, state.options.sortDesc, state.options.page, state.options.itemsPerPage, state.filter).then(response => {
        // check if current options are still valid : last load may have changed the number of items, so current page may not be valid for example
        let nbpages = Math.ceil(response.total/state.options.itemsPerPage);
        if (nbpages<state.options.page) {
          let newOptions = state.options;
          if (nbpages>0) {
            newOptions.page = nbpages;
          } else {
            newOptions.page = 1;
          }
          commit('SAVE_OPTIONS',newOptions);
        }
        commit('SAVE_ITEMS', response);
      }).catch().finally( () => {
        commit('SAVE_LOADING', false);
      });
    },
    patchItem({commit}, data) {
      const { id, item } = data;
      commit('SAVE_LOADING', true);
      // replace here by the real service
      CommunityMemberService.patchItem(id, item).then(response => {
        commit('SAVE_ITEM', response);
      }).catch( () => {
        commit('SAVE_ERROR', true);
      }).finally( () => {
        commit('SAVE_LOADING', false);
      });
    },
    // associate users to a given campaign
    associate({commit, state}, { campaign, filterType, deliveries, community }) {
      commit('SAVE_LOADING', true);
      CommunityMemberService.associate(campaign,filterType,state.filter,deliveries,community).then( () => {
        commit('SAVE_ASSOCIATED', 1);
        MessageService.success('associated','community');
      }).catch( () => {
        commit('SAVE_ASSOCIATED', 0);
        MessageService.error('associated','community');
        commit('SAVE_ERROR', true);
      }).finally( () => {
        commit('SAVE_LOADING', false);
      });
    },
    // send emails of a campaign
    sendCampaign({commit}, { campaign, mode, filters }) {
      commit('SAVE_ERROR', false); // not really nice but just to avoid eslint warning !
      commit('SAVE_LOADING', true);
      CommunityMemberService.sendCampaign(campaign,mode,filters).then( () => {
        if (mode == 1) {
          commit('SAVE_SENTTEST', true);
          MessageService.success('campaignTest','community');
        } else if (mode == 2) {
          commit('SAVE_SENTPROD', true);
          MessageService.success('campaignProd','community');
        }
      }).catch( () => {
        if (mode == 1) {
          commit('SAVE_SENTTEST', false);
          MessageService.error('campaignTest','community');
        } else if (mode == 2) {
          commit('SAVE_SENTPROD', false);
          MessageService.error('campaignProd','community');
        }
        commit('SAVE_ERROR', true);
      }).finally( () => {
        commit('SAVE_LOADING', false);
      });
    },
    saveAssociated({commit}, val) {
      commit('SAVE_ASSOCIATED', val);
    },
    saveSentTest({commit}, val) {
      commit('SAVE_SENTTEST', val);
    },
    saveSentProd({commit}, val) {
      commit('SAVE_SENTPROD', val);
    },
    deleteItem({commit}, id) {
      commit('SAVE_LOADING', true);
      CommunityMemberService.deleteItem(id).then( () => {
        MessageService.success('deleted','community_user');
        commit('SAVE_ITEM', null);
      }).catch( () => {
        MessageService.error('deleted','community_user');
        commit('SAVE_ERROR', true);
      }).finally( () => {
        commit('SAVE_LOADING', false);
      });
    }
  }
};
