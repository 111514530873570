// Muser is a MobiAdmin user
export default class Muser {
  constructor(email, password) {
    this.id = null;
    this.givenName = '';
    this.shortFamilyName = '';
    this.email = email;
    this.password = password;
    this.lastActivityDate = new Date();
    this.solidaryStructures = [];
    this.currentSolidaryStructure = null;
    this.solidaryEdit = false;
  }
}