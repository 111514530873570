import UserService from '../services/user.service';
import MessageService from '../services/message.service';
import { initialState, defaultActions, defaultMutations, defaultGetters } from './common.module';
import userService from '../services/user.service';
import ImportService from '../services/import.service';
import CsvExport from '../services/csvExport';

export const userModule = {
  namespaced:true,
  state: {
    ...initialState(),
    associated: null,
    sentTest: null,
    sentProd: null,
    residenceAddressDisabled: true
  },
  mutations: {
    ...defaultMutations(),
    // add custom mutations
    SAVE_ASSOCIATED(state, val) {
      state.associated = val;
    },
    SAVE_SENTTEST(state, val) {
      state.sentTest = val;
    },
    SAVE_SENTPROD(state, val) {
      state.sentProd = val;
    },
    DISABLED_RESIDENCE_ADDRESS(state, val) {
      state.residenceAddressDisabled = val;
    }
  },
  getters: {
    ...defaultGetters(),
    associated (state) {
      return state.associated;
    },
    sentTest (state) {
      return state.sentTest;
    },
    sentProd (state) {
      return state.sentProd;
    }
  },
  actions: {
    ...defaultActions(),
    loadItems({commit, state}, primary=true) {
      commit('SAVE_LOADING', true);
      // replace here by the real service
      UserService.getItems(state.options.sortBy, state.options.sortDesc, state.options.page, state.options.itemsPerPage, state.filter).then(response => {
        if (primary) {
          // this load concerns the main component => it's not a load to populate a subcomponent, we need to check the options
          // check if current options are still valid : last load may have changed the number of items, so current page may not be valid for example
          let nbpages = Math.ceil(response.total/state.options.itemsPerPage);
          if (nbpages<state.options.page) {
            let newOptions = state.options;
            if (nbpages>0) {
              newOptions.page = nbpages;
            } else {
              newOptions.page = 1;
            }
            commit('SAVE_OPTIONS',newOptions);
          }
        }
        commit('SAVE_ITEMS', response);
      }).catch( () => {
        commit('SAVE_ERROR', true);
      }).finally( () => {
        commit('SAVE_LOADING', false);
      });
    },
    loadItem({commit, state}, { id, fields} ) {
      // clear item if given id is different than current id
      if (state.item && state.item.id && id != state.item.id) {
        commit('SAVE_ITEM', null);
      }
      commit('SAVE_LOADING', true);
      // replace here by the real service
      UserService.getItem(id).then(response => {
        // complete the item with potential nullable fields that were not retrieved
        fields.forEach( item => {
          if (response[item] === undefined) {
            response[item] = null;
          }
        });
        // add required fields for image management
        response.newImage = null;
        response.resetImage = false;
        commit('SAVE_ITEM', response);
      }).catch( error => {
        MessageService.error('loaded','user');
        commit('SAVE_ERROR', true);
        return error;
      }).finally( () => {
        commit('SAVE_LOADING', false);
      });
    },
    postItem({commit}, item) {
      commit('SAVE_LOADING', true);
      UserService.postItem(item).then(response => {
        // check for a new image 
        if (item.newImage) {
          let formData = new FormData();
          formData.append('userId', response.id);
          formData.append('userFile', item.newImage);
          formData.append('position', 1);
          UserService.postImage(formData).then( image => {
            let completedResponse = {
              ...response,
              newImage: null,
              resetImage: false, // no need to reset the image field, we will redirect to the user list
              image: image.image,
              avatar: image.avatar
            };
            commit('SAVE_ITEM', completedResponse);
            MessageService.success('created','user');
          }).catch( () => {
            MessageService.error('created','user');
            commit('SAVE_ERROR', true);
          });
        } else {
          commit('SAVE_ITEM', response);
          MessageService.success('created','user');
        }
      }).catch( () => {
        MessageService.error('created','user');
        commit('SAVE_ERROR', true);
      }).finally( () => {
        commit('SAVE_LOADING', false);
      });
    },
    patchItem({commit}, data) {
      const { id, item } = data;
      commit('SAVE_LOADING', true);
      UserService.patchItem(id, item).then(response => {
        // check for a new image 
        if (item.newImage) {
          let formData = new FormData();
          formData.append('userId', id);
          formData.append('userFile', item.newImage);
          formData.append('position', 1);
          UserService.postImage(formData).then( image => {
            let completedResponse = {
              ...response,
              newImage: null,
              resetImage: true, // to clear the new image in the image field component
              image: image.image,
              avatar: image.avatar
            };
            commit('SAVE_ITEM', completedResponse);
            MessageService.success('updated','user');
          }).catch( () => {
            MessageService.error('updated','user');
            commit('SAVE_ERROR', true);
          });
        } else {
          // add required fields for image management
          response.newImage = null;
          response.resetImage = false;
          commit('DISABLED_RESIDENCE_ADDRESS', true);
          commit('SAVE_ITEM', response);
          MessageService.success('updated','user');
        }
      }).catch( () => {
        MessageService.error('updated','user');
        commit('SAVE_ERROR', true);
      }).finally( () => {
        commit('SAVE_LOADING', false);
      });
    },
    deleteItems({commit}, params) {
      commit('SAVE_LOADING', true);
      UserService.deleteItems(params).then(() => {
        MessageService.success('deleted','user');
        commit('SAVE_ITEM', null);
      }).catch(() => {
        MessageService.error('deleted', 'user');
        commit('SAVE_ERROR', true);
      }).finally( () => {
        commit('SAVE_LOADING', false);
      });
    },
    deleteItem({commit}, id) {
      commit('SAVE_LOADING', true);
      UserService.deleteItem(id).then( () => {
        MessageService.success('deleted','user');
        commit('SAVE_ITEM', null);
      }).catch( () => {
        MessageService.error('deleted','user');
        commit('SAVE_ERROR', true);
      }).finally( () => {
        commit('SAVE_LOADING', false);
      });
    },
    // associate users to a given campaign
    associate({commit, state}, { campaign, filterType, deliveries }) {
      commit('SAVE_LOADING', true);
      UserService.associate(campaign,filterType,state.filter,deliveries).then( () => {
        commit('SAVE_ASSOCIATED', 1);
        MessageService.success('associated','user');
      }).catch( () => {
        commit('SAVE_ASSOCIATED', 0);
        MessageService.error('associated','user');
        commit('SAVE_ERROR', true);
      }).finally( () => {
        commit('SAVE_LOADING', false);
      });
    },
    // send emails of a campaign
    sendCampaign({commit}, { campaign, mode, filters }) {
      commit('SAVE_ERROR', false); // not really nice but just to avoid eslint warning !
      commit('SAVE_LOADING', true);
      UserService.sendCampaign(campaign,mode,filters).then( () => {
        if (mode == 1) {
          commit('SAVE_SENTTEST', true);
          MessageService.success('campaignTest','user');
        } else if (mode == 2) {
          commit('SAVE_SENTPROD', true);
          MessageService.success('campaignProd','user');
        }
      }).catch( () => {
        if (mode == 1) {
          commit('SAVE_SENTTEST', false);
          MessageService.error('campaignTest','user');
        } else if (mode == 2) {
          commit('SAVE_SENTPROD', false);
          MessageService.error('campaignProd','user');
        }
        commit('SAVE_ERROR', true);
      }).finally( () => {
        commit('SAVE_LOADING', false);
      });
    },
    saveAssociated({commit}, val) {
      commit('SAVE_ASSOCIATED', val);
    },
    saveSentTest({commit}, val) {
      commit('SAVE_SENTTEST', val);
    },
    saveSentProd({commit}, val) {
      commit('SAVE_SENTPROD', val);
    },
    exportAll({commit}) {
      commit('SAVE_LOADING_EXPORT', true);
      userService.exportAll().then((r) => {
        if (r.data) {
          CsvExport.exportToCsv('users', r.data);
        }
      }).finally(() => {
        commit('SAVE_LOADING_EXPORT', false);
      })
    },
    exportSelected({commit}, selection) {
      commit('SAVE_LOADING_EXPORT', true);
      userService.exportSelected(selection).then((r) => {
        if (r.data) {
          CsvExport.exportToCsv('users', r.data);
        }
      }).finally(() => {
        commit('SAVE_LOADING_EXPORT', false);
      })
    },
    exportFiltered({commit}, filter) {
      commit('SAVE_LOADING_EXPORT', true);
      userService.exportFiltered(filter).then((r) => {
        if (r.data) {
          CsvExport.exportToCsv('users', r.data);
        }
      }).finally(() => {
        commit('SAVE_LOADING_EXPORT', false);
      })
    },
    importCollection({commit}, data){
      if(data.file && data.file instanceof File){
        let formData = new FormData();
        formData.append('file', data.file);
        formData.append('filename', data.file.name);
        formData.append('columnHeadersFirstLine', data.columnHeadersFirstLine);

        commit('IMPORT_LOADING', true);
        ImportService.postFile(formData, 'users').then(response => {
          commit('IMPORT_RESPONSE', response);  
          commit('IMPORT_LOADING', false);
          }
        );
      }
    }
  }
};
